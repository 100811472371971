import {
  ReactElement,
  SyntheticEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import { Table } from "react-bootstrap";
import {
  Icon,
  UserFilterSection,
  UserlistAction,
  Wrapper,
} from "./subcomponents";
import { tableHeader } from "./const";
import {
  TableHeader,
  TableRow,
  TableWrapper,
  PageWrapper,
  FlexWrapper,
  SectionTitle,
  Loader,
  Input,
  Pagination,
  ContainerWrapper,
  CountTitle,
} from "../../../components";
import strings from "../../../locale/en";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import {
  handleGetAssignCategory,
  setAssignCategorySelected,
} from "../../../redux/AssignCategory/action";
import {
  getCategoryDropdown,
  getStreamDropdown,
} from "../../../helpers/dropdown";
import {
  addAssignCategoryInput,
  handleAssignDelete,
} from "../../../redux/AssignCategory/api";
import {
  AddAssignCategory,
  assignCategoryList,
} from "../../../redux/AssignCategory/types";
import { handleStreamMaster } from "../../../redux/streamMaster/action";
import { handleGetCategory } from "../../../redux/AddCategory/action";
import { DropdownListProps } from "../../../components/EditableDropdown/typings";
import { Modal as BootstrapModal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const {
  user: {
    category: { assignCategorystr },
  },
} = strings;

const AssignCategory = (): ReactElement => {
  const {
    assign: { isLoading },
    assignList,
    categoryList,
    assignCategory,
    stream,
    assignListModal,
  } = useSelector(
    (state: RootState) => ({
      assign: state.AssignReducer,
      assignList: state.AssignReducer.AssignCategoryStream,
      categoryList: state.categorylist.categories,
      stream: state.streamList.streamMaster,
      assignCategory: state.AssignReducer
        .selectcategoryStream as AddAssignCategory,
      assignListModal: state.AssignReducer
        .selectedAssignDetails as assignCategoryList,
    }),
    shallowEqual
  );

  const StreamDropdown = stream ? getStreamDropdown(stream) : [];
  const CategoryDropdown = categoryList
    ? getCategoryDropdown(categoryList)
    : [];

  const streamRef = useRef(null);
  const categoryRef = useRef(null);
  const [showModal, setShowModal] = useState('');
  const [values, setValues] = useState(assignCategory);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [dataPerPage, setDataPerPage] = useState(10);

  //Get current data
  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = assignList.slice(indexOfFirstData, indexOfLastData);
  const dispatch = useDispatch();

  const resetValues = () => {
    streamRef?.current?.resetSelectedValues();
    categoryRef?.current.resetSelectedValues();
  };

  const canSave = !!values?.categoryList && !!values?.streamName;

  useEffect(() => {
    dispatch(handleGetCategory());
    dispatch(handleStreamMaster());
    dispatch(handleGetAssignCategory());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageWrapper>
      <ContainerWrapper>
        <CountTitle title="Assign Category" count={assignList.length} />
        <FlexWrapper justifyContent="space-between" noPadding>
          <SectionTitle title={assignCategorystr} />
        </FlexWrapper>
        <UserFilterSection
          categoryList={CategoryDropdown}
          handleAssignSelect={(value) => { 
            setValues({
              ...values,
              categoryList: value,
            });
          }}
          assignList={StreamDropdown}
          handleStreamSelect={([value]: Array<DropdownListProps>) => {
            setValues({
              ...values,
              streamName: value?.name,
              streamID: value?.id,
            });
          }}
          handleSearch={(e: SyntheticEvent) => {
            e.preventDefault();
            dispatch(addAssignCategoryInput(values));
            resetValues();
          }}
          buttonDisabled={!canSave}
          streamRef={streamRef}
          categoryRef={categoryRef}
        />
        <FlexWrapper justifyContent="space-between">
          <SectionTitle title="Assigned Category" />
          <Input
            value={search}
            onChange={(value: string) => {
              setSearch(value);
            }}
            width="25%"
            placeholder="Search"
          />
        </FlexWrapper>
        {isLoading ? (
          <Loader />
        ) : (
          <div style={{ marginTop: "50px" }}>
            <TableWrapper>
              <Table
                size="sm"
                responsive="sm"
                borderless
                className="table table-striped"
              >
                <TableHeader>
                  <TableRow>
                    {tableHeader?.map((header, index) => (
                      <th key={`complete-session-header-${index}`}>{header}</th>
                    ))}
                  </TableRow>
                </TableHeader>
                <tbody>
                  {currentData
                    // eslint-disable-next-line array-callback-return
                    .filter((listValues) => {
                      if (search === "") {
                        return listValues;
                      } else if (
                        listValues?.name
                          .toLowerCase()
                          .includes(search.toLowerCase())
                      ) {
                        return listValues;
                      }
                    })
                    .map((categoryInfo, index) => {
                      const { name } = categoryInfo;
                      return (
                        <TableRow key={`user-${index}`}>
                          <td>{index + 1 + indexOfFirstData}</td>
                          <td>{name}</td>
                          <td>
                            <UserlistAction
                              handleView={() => {
                                dispatch(
                                  setAssignCategorySelected(categoryInfo)
                                ); 
                                setShowModal('cat');
                              }}
                            />
                          </td>
                        </TableRow>
                      );
                    })}
                </tbody>
              </Table>
            </TableWrapper>
            <Pagination
              currentData={indexOfLastData}
              dataLimit={assignList.length}
              currentPage={currentPage}
              dataPerPage={dataPerPage}
              handleCurrentPage={setCurrentPage}
            />
          </div>
        )}
        {showModal && (
          <BootstrapModal
            show={true}
            onHide={() => setShowModal('')}
            size="lg"
          >
            <BootstrapModal.Header closeButton>
              Assigned Stream List
            </BootstrapModal.Header>
            <BootstrapModal.Body>
              <Wrapper>
                {assignListModal.Category.map((categoryinfo, index) => (
                  <div>
                    {index + 1}:Category Name:{''}
                    <b>{categoryinfo?.category_name}</b>
                    <Icon
                      variant="outline-light"
                      onClick={() => {
                        dispatch(
                          handleAssignDelete({
                            id: categoryinfo?.assign_category_ID,
                          })
                        );
                        setShowModal('');
                      }}
                    >
                      <FontAwesomeIcon icon={["far", "trash-alt"]} />
                    </Icon>
                  </div>
                ))}
              </Wrapper>
            </BootstrapModal.Body>
          </BootstrapModal>
        )}
      </ContainerWrapper>
    </PageWrapper>
  );
};

export default AssignCategory;
