export const IsMultipleDP = [
  {
    id: "True",
    name: "True",
  },
  {
    id: "False",
    name: "False",
  },
  {
    id: "others",
    name: "Others",
  },
];
