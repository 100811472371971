import { ReactElement } from "react";
import styled from "styled-components";
import { weight } from "../../const/fonts";
import { Button } from "react-bootstrap";
import { colors } from "../../const/theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UserlistActionProps, UserFilterProps } from "./typings";
import {
  FlexWrapper,
  DropdownWrapper,
  Dropdown,
  ActionButton,
} from "../../components";
import { PrimaryButton, SecondaryButton } from "../../components/Button";
import { ViewButton } from "../Questioniar/AddQuestion/createTest/subcomponents";

export const PageWrapper = styled.div`
  width: 80%;
`;

export const TITLE = styled.p`
  font-size: 24px;
  font-weight: ${weight.bold};
  padding: 4%;
  padding-bottom: 2%;
`;

const UserlistWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const Icon = styled(Button)`
  border: none;
  color: rgb(242, 127, 54);
  background: none;
  text-transform: capitalize;
  &:hover,
  &:focus {
    border-color: none;
    background: none;
    color: ${colors.black};
  }
  max-width: 40px;
`;

export const DeleteButton = styled(Button)`
  background-color: red;
  color: ${colors.white};
  border: none;
  font-family: "Montserrat", sans-serif;
  &:hover,
  &:focus,
  &:hover,
  &:active {
    background: #e06b6b;
    color: red;
    border-color: ${({ theme }) => theme.button.primary};
  }
  margin: auto 5px;
  cursor: pointer;
  height: auto;
  font-size: 11px;
  @media (max-width: 500px) {
    margin: 0 auto;
    background-color: red;
  }
`;

export const UserFilterSection = ({
  handleRoleChange,
  handleAdd,
  roleList,
  value,
}: UserFilterProps): ReactElement => {
  return (
    <>
      <FlexWrapper>
        <DropdownWrapper>
          <Dropdown
            isRequired
            dropdownList={roleList}
            placeholder={"Stream"}
            handleSelect={handleRoleChange}
          />
        </DropdownWrapper>
        <div>
          <PrimaryButton onClick={handleAdd}>Submit</PrimaryButton>
        </div>
      </FlexWrapper>
    </>
  );
};

export const UserlistAction = ({
  handleView,
  handleEdit,
  handleDelete,
}: UserlistActionProps): ReactElement => {
  return (
    <UserlistWrapper>
      <ViewButton onClick={handleView}>View</ViewButton>
      <DeleteButton onClick={handleDelete}>Delete</DeleteButton>
      {/* <SecondaryButton onClick={handleEdit}>Edit</SecondaryButton> */}
    </UserlistWrapper>
  );
};
