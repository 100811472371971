import { validateRequired } from "../../../../helpers/formValidation";
import { ValidateAddQuestionForm } from "./typings";

const validateAddQuestionsForm = ({
  formValues,
  errors,
  field,
}: ValidateAddQuestionForm): Record<string, string> => {
  let formErrors: Record<string, string> = { ...errors };
  const { question, answer, categoryName } = formValues;

  switch (field) {
    case "answer":
      formErrors["answer"] = validateRequired(answer);
      break;
    case "question":
      formErrors["question"] = validateRequired(question);
      break;
    case "categoryName":
      formErrors["categoryName"] = validateRequired(categoryName);
      break;
    default:
      formErrors["question"] = validateRequired(question);
      formErrors["answer"] = validateRequired(answer);
      formErrors["categoryName"] = validateRequired(categoryName);
  }

  return formErrors;
};

export default validateAddQuestionsForm;
