import styled from "styled-components";
import { InputGroup, FormControl, Button } from "react-bootstrap";
import { colors } from "../../const/theme";
import fonts from "../../const/fonts";

export const HeaderWrapper = styled.header`
  width: 100%;
  height: 70px;
  display: flex;
  background: #ffffff;
  border-bottom: 1px solid lightgray;
`;

export const LogoWrapper = styled.div`
 width: 95px;
  height: 45px;
  display: flex;
  margin: auto 2rem;
`;

export const HamburgerMenu = styled(Button)`
  background: none;
  border: none;
  font-size: ${fonts.medium}px;
  color: ${({ theme }) => theme?.hamburgerMenu};
`;
export const ProfileWrapper = styled.div`
  display: flex;
  width: 65%;
  float: right;
  margin-left: auto;
  position: relative;
  @media (min-width: 769px) {
    width: 40%;
  }
`;

export const CourseList = styled(InputGroup)`
  margin: auto 0;
  width: 100%;
  #course-id {
    border: 1px solid ${({ theme }) => theme.border};
    &:hover,
    &:focus {
      background: ${({ theme: { dropDown } }) => dropDown.background};
      color: ${({ theme: { dropDown } }) => dropDown.color};
    }
    color: ${colors.white};
  }
  @media (min-width: 769px) {
    width: 50%;
  }
`;

export const UserId = styled.div`
  color: #2d2d2e;
  margin: auto 12px;
  font-size: 13px;
  @media (max-width: 415px) {
    display: none;
  }
`;

export const DesktopWrapper = styled.div`
  display: flex;
  width: 100%;
  @media (max-width: 415px) {
    display: none;
  }
`;
export const MobileWrapper = styled.div`
  display: flex;
  @media (min-width: 415px) {
    display: none;
  }
`;

export const CourseInput = styled(FormControl)`
  background: ${colors.white} !important;
`;

export const ProfileContainer = styled.div`
  margin: auto;
  margin-right: 25px;
  border: 2px solid #3335cf;
  border-radius: 20px;
  width: auto;
`;
export const ProfileImage = styled.img`
  height: 27px;
  min-width: 27px;
  position: relative;
  padding: 0%;
  overflow: hidden;
  border-radius: 50%;
`;
export const UserName = styled.label`
  color: #ff7b00;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 15px;
  margin: auto 16px;
  @media (max-width: 415px) {
    display: none;
  }
`;

export const Logo = styled.img`
  margin: 0 auto;
  height: 100%;
  width: 100%;
`