import { ReactElement } from "react"
import { useSelector } from "react-redux"
import { CardWrapper, Column, ContainerWrapper, FlexWrapper, PageWrapper, SectionTitle } from "../../../components"
import strings from "../../../locale/en"
import { RootState } from "../../../redux/store"
import { UserList } from "../../../redux/userlist/types"

const UserDetails = (): ReactElement => {
    const {
        userDetails
    } = useSelector(
        (state: RootState) => ({
            userDetails: state.userDetailsList.setSelectUser as UserList
        })
    )
    const {
        details: {
            userDetails: {
                firstName,
                lastName,
                email,
                contactno,
                city,
                state,
                country,
                profile,
            }
        }
    } = strings

    return (
        <PageWrapper id="container">
            <ContainerWrapper>
                <div>
                    <SectionTitle title="User Details" hasBackButton />
                </div>
                <FlexWrapper justifyContent="space-around">
                    <CardWrapper>
                        <Column keyName={firstName} value={userDetails?.user_name} />
                        <Column keyName={lastName} value={userDetails?.last_name} />
                        <Column keyName={profile} value={userDetails?.profile} />
                        <Column keyName={email} value={userDetails?.email} />
                    </CardWrapper>
                    <CardWrapper>
                        <Column keyName={city} value={userDetails?.city} />
                        <Column keyName={state} value={userDetails?.state} />
                        <Column keyName={country} value={userDetails?.country} />
                        <Column keyName={contactno} value={userDetails?.mobile} />
                    </CardWrapper>
                </FlexWrapper>
            </ContainerWrapper>
        </PageWrapper>
    )
}

export default UserDetails;
