export const QuestionManagementtableHeader = [
  "S.no",
  "Stream Name",
  "View Test",
];
export const tableHeader = [
  "Test ID",
  "Test Name",
  "Stream Name",
  "Level", 
  'Actions',
  "Add Question",
];

export const ViewQuestiontableHeader = [
  "S.no", 
  "Questions", 
  "Answer", 
  "Category", 
  'Actions'
];
