import strings from "../locale/en";
import ROUTES from "./routes";

const user = [
  {
    icon: ["fas", "line-chart"],
    label: strings.user.dashboard.title,
    to: ROUTES.DASHBOARD,
  },
  {
    icon: ["fas", "user-group"],
    label: strings.user.title,
    to: ROUTES.USER,
  },
  {
    icon: ["fas", "people-arrows"],
    label: strings.user.streamMaster.title,
    to: ROUTES.STREAM,
  },
  {
    icon: ["fas", "layer-group"],
    label: strings.user.category.title,
    to: "",
    childs: [
      {
        label: strings.user.category.addCategort,
        to: ROUTES.ADD_CATEGORY,
      },
      {
        label: strings.user.category.assignCategorystr,
        to: ROUTES.ASSIGN_CATEGORY,
      },
      {
        label: "Tools",
        to: ROUTES.TOOLS,
      },
    ],
  },
  {
    icon: ["fas", "clipboard-list"],
    label: strings.user.questionManagement.title,
    to: ROUTES.QUESTIONNAIRE_MANAGEMENT,
  },
  {
    icon: ["fas", "square-poll-vertical"],
    label: strings.user.result.title,
    to: ROUTES.RESULT,
  },
];

const menus = {
  user: user,
};

export default menus;
