import { ReactElement, useEffect } from "react";
import { ThemeProvider, createGlobalStyle } from "styled-components";
import theme, { colors } from "./const/theme";
import SideNavigation from "./container/SideNavigation";
import Header from "./components/Header";
import { Router } from "react-router-dom";
import styled from "styled-components";
import history from "./const/history";
import Routes from "./routes";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { RootState } from "./redux/store";
import BREAKPOINTS from "./const/breakpoint";
import useBreakpoint from "use-breakpoint";
import { updateIsMenuOpen } from "./redux/menu/actions";

interface BodyProps {
  isShowOverlay?: boolean;
}

const Body = styled.div<BodyProps>`
  display: flex;
  min-height: calc(100vh - 40px);
`;

interface GlobalStylesProps {
  isMobileMenuOpen?: boolean;
}

export const GlobalStyles = createGlobalStyle<GlobalStylesProps>`
  body {
    @import url('https://fonts.googleapis.com/css?family=Open+Sans');
    font-family: "Open Sans", sans-serif;
    overflow: ${({ isMobileMenuOpen }) =>
    isMobileMenuOpen ? "hidden" : "auto"};
  }
  #container {
    z-index: ${({ isMobileMenuOpen }) => (isMobileMenuOpen ? "-1" : "0")};
  }
  .react-datepicker-popper {
    z-index: 999;
    padding: 0 !important;
  }
  .input-group-text {
    background: none;
    border: none;
  }
  td { 
    text-align: center;
    font-family: "Montserrat", sans-serif;
    font-weight:450;
    font-size  : 13.5px;
    color: #141A45;
  }
  th {  
    text-align: center;  
    font-weight: 650;
    font-size: 13px;
    font-family: "Montserrat", sans-serif;  
    height: 30px;
  }
  .btn-link {
    color: ${colors.purple};
  }
  .btn-link:hover {
    color: ${colors.lightGrey};
  }
  .btn:focus {
    box-shadow: none;
  }
  .react-datepicker-wrapper {
    .form-control.is-valid {
        border-color: ${colors.purple};
    }
  }
  
`;

const App = (): ReactElement => {
  const { isLoggedIn, isMenuOpen } = useSelector(
    (state: RootState) => ({
      isLoggedIn: state.user.isLoggedIn,
      isMenuOpen: state.menu.isMenuOpen,
    }),
    shallowEqual
  );
  const { breakpoint } = useBreakpoint(BREAKPOINTS);
  const isMobileMenuOpen = isMenuOpen && breakpoint === "mobile";
  const dispatch = useDispatch();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [breakpoint]);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles isMobileMenuOpen={isMobileMenuOpen} />
      <Router history={history}>
        {isLoggedIn && <Header />}
        <Body
          isShowOverlay={isMobileMenuOpen}
          onClick={() => {
            setTimeout(() => {
              if (isMobileMenuOpen) {
                dispatch(updateIsMenuOpen(false));
              }
            }, 500);
          }}
        >
          {isLoggedIn && <SideNavigation />}
          <Routes />
        </Body>
      </Router>
    </ThemeProvider>
  );
};

export default App;
