/* eslint-disable no-unreachable */
import { createAsyncThunk } from "@reduxjs/toolkit";
import apiEndpoints from "../../const/apiEndpoints";
import history from "../../const/history";
import api from "../../services";
import {
  CreateTest,
  CreateTestResponse,
  GetAssignCategoryByIdPayload,
  GetAssignCategoryByIdResponse,
  GetLevelsResponse,
  GetQuestion,
  GetQuestionResponse,
  GetTest,
  GetTestById,
  ProductHeadResponse,
  getQuestionPayload,
  getQuestionResponse,
  deletePayload
} from "./types";

export const getTest = createAsyncThunk(
  "test/getTest",
  async (requestPayload: GetTestById): Promise<Array<GetTest>> => {
    const response = await api.post(`${apiEndpoints.getTest}`, requestPayload);
    return response.data.message;
  }
);

export const CreateTestApi = createAsyncThunk(
  "test/CreateTest",
  async (requestPayload: CreateTest, { dispatch }): Promise<CreateTest> => {
    const response = await api.post(
      `${apiEndpoints.CreateTest}`,
      requestPayload
    );
    if (response) {
      dispatch(
        getTest({
          streamID: requestPayload.streamID,
        })
      );
    }
    return response.data;
  }
);

export const getLevels = createAsyncThunk(
  "streamMaster",
  async (_undefined): Promise<GetLevelsResponse> => {
    const response = await api.get(apiEndpoints.getLevels);
    return response.data.message;
  }
);

export const createQuestion = createAsyncThunk(
  " question/createQuestion",
  async (
    requestPayload: CreateTestResponse | ProductHeadResponse
  ): Promise<CreateTestResponse> => {
    const response = await api.post(
      apiEndpoints.CreateQuestion,
      requestPayload
    );
    return response.data;
  }
);

export const getQuestion = createAsyncThunk(
  " question/getQuestion",
  async (requestPayload: GetQuestion): Promise<GetQuestionResponse> => {
    const response = await api.post(
      apiEndpoints.CreateQuestion,
      requestPayload
    );
    return response.data;
  }
);

export const getAssignCategoryById = createAsyncThunk(
  " question/getQuestion",
  async (
    requestPayload: GetAssignCategoryByIdPayload
  ): Promise<Array<GetAssignCategoryByIdResponse>> => {
    const response = await api.post(
      apiEndpoints.getAssignCategoryById,
      requestPayload
    );
    return response.data.message;
  }
);

export const GetQuestionApi = createAsyncThunk(
  'question/getQuestionById',
  async (requestPayload: getQuestionPayload): Promise<any> => {
    const response = await api.post(apiEndpoints.getQuestion, requestPayload)
    return response?.data.message
  }
)

export const editQuestion = createAsyncThunk(
  'question/editQuestion',
  async (payload: getQuestionResponse, { dispatch }): Promise<getQuestionResponse> => {
    const response = await api.post(`${apiEndpoints.editQuestion}`, payload)
    if (response?.data) {
      history.goBack()
    }
    return response?.data
  }
)

export const deleteQuestion = createAsyncThunk(
  "user/deleteUser",
  async (
    requestPayload: deletePayload,
  ): Promise<getQuestionResponse> => {
    const response = await api.post(
      `${apiEndpoints.deleteQuestion}`,
      requestPayload
    );
    if (response?.data) {
      history.goBack()
    }
    return response?.data.message;
  }
);

export const deleteTest = createAsyncThunk(
  'test/delete',
  async (requestPayload: deletePayload): Promise<Array<GetTest>> => {
    const response = await api.post(`${apiEndpoints.deleteTest}`, requestPayload)
    if (response?.data) {
      history.goBack()
    }
    return response?.data
  }
)