import { UserList } from "../redux/userlist/types";
import { DropdownListProps } from "../components/EditableDropdown/typings";
import {
  categoryList,
  getCourseprops,
  GetTools,
} from "../redux/AddCategory/types";
import { StreamList } from "../redux/streamMaster/types";
import {
  GetAssignCategoryByIdResponse,
  GetLevels,
} from "../redux/QuestionaryManagement/types";
import { DropdownListPropsMultiSelect } from "../components/Dropdown/typings";
import { getCatergorybySteram } from '../redux/AddCategory/types'

export const getUserListDropdown = (
  userlists: Array<UserList>
): Array<DropdownListProps> => {
  const userListses = userlists.map((userlists: UserList) => ({
    id: userlists?.id,
    name: userlists?.profile,
  }));
  return userListses;
};

export const getStreamDropdown = (
  stream: Array<StreamList>
): Array<DropdownListProps> => {
  const streames = stream.map((stream: StreamList) => ({
    id: stream?.id,
    name: stream?.name,
  }));
  return streames;
};

export const getCategoryDropdown = (
  category: Array<categoryList>
): Array<DropdownListProps> => {
  const categories = category.map((cat: categoryList) => ({
    id: cat?.id,
    name: cat?.name,
  }));
  return categories;
};

export const getLevelsDropdown = (
  level: Array<GetLevels>
): Array<DropdownListProps> => {
  const levellist = level.map((level: GetLevels) => ({
    id: level?.id,
    name: level?.test_level,
  }));
  return levellist;
};

export const getAssignCategoryByIdDropdown = (
  assignCategory: Array<GetAssignCategoryByIdResponse>
): Array<DropdownListProps> => {
  const assignCategorybyId = assignCategory.map(
    (assignCategory: GetAssignCategoryByIdResponse) => ({
      id: assignCategory?.category_id,
      name: assignCategory?.category_name,
    })
  );
  return assignCategorybyId;
};

export const getToolsDropdown = (
  tool: Array<GetTools>
): Array<DropdownListProps> => {
  const toolses = tool.map((tools: GetTools) => ({
    id: tools?.tool_id,
    name: tools?.toolName,
  }));
  return toolses;
};

export const getCourseDropdown = (
  courseMap: Array<getCourseprops>
): Array<DropdownListPropsMultiSelect> => {
  const courses = courseMap.map((cou: getCourseprops) => ({
    id: `${cou?.id}`,
    name: cou?.short_title,
    link: cou?.course_link
  }));
  return courses;
};

export const getCategoryByStreamDropdown = (
  catArray : Array<getCatergorybySteram>
): Array<DropdownListProps> => {
  const categoryList = catArray.map((data: getCatergorybySteram) => ({
    id: data?.category_id,
    name: data?.category_name
  }))
  return categoryList
}