import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ReactElement } from 'react'
import {
  DesktopWrapper,
  HamburgerMenu,
  Logo,
  LogoWrapper,
} from './subcomponents'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../redux/store'
import { updateIsMenuOpen } from '../../redux/menu/actions'
import logoImg from '../../asserts/logo.png'

const DesktopMenu = (): ReactElement => {
  const { isMenuOpen } = useSelector((state: RootState) => state.menu)
  const dispatch = useDispatch()

  return (
    <DesktopWrapper>
      <LogoWrapper>
        <Logo src={logoImg} />
      </LogoWrapper>
      <HamburgerMenu
        variant="light"
        onClick={() => dispatch(updateIsMenuOpen(!isMenuOpen))}
      >
        <FontAwesomeIcon icon={['fas', 'bars']} size="lg" />
      </HamburgerMenu>
    </DesktopWrapper>
  )
}

export default DesktopMenu
