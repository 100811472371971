import { ReactElement } from 'react'
import { Input, PageWrapper } from '../../../../components'
import { ToolsEditProps } from '../types'

const ToolsEdit = ({ editValues, setEditValues }: ToolsEditProps): ReactElement => {
    return (
        <PageWrapper>
            <Input
                value={editValues?.toolName || ''}
                placeholder="Tool Name"
                label='Tool Name'
                isRequired
                boxShadow
                onChange={(value: string) => {
                    setEditValues({ ...editValues, toolName: value })
                }}
            />
        </PageWrapper>
    )
}

export default ToolsEdit