import { ReactElement, useState, useEffect } from "react";
import {
  ContainerWrapper,
  DeleteModal,
  DropdownWrapper,
  FlexWrapper,
  Input,
  Modal,
  PageWrapper,
  SectionTitle,
  TableHeader,
  TableRow,
  TableWrapper,
} from "../../../../../components";
//import { useHistory } from "react-router-dom";
import { ViewQuestiontableHeader } from "../../../const";
import { Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { updateSelectedQuestion } from "../../../../../redux/QuestionaryManagement/actions";
import { getQuestionResponse } from "../../../../../redux/QuestionaryManagement/types";
import {
  deleteQuestion,
  editQuestion,
} from "../../../../../redux/QuestionaryManagement/api";
import { DeleteButton, EditButton } from "./subcomponents";

const QuestionTables = (): ReactElement => {
  const { updateQuestin, questionData, selectedTestName } = useSelector(
    (state: RootState) => ({
      updateQuestin: state.questionaryManagement.updateQuestin,
      questionData: state.questionaryManagement
        .selectQuestionEdit as getQuestionResponse,
      selectedTestName: state.questionaryManagement.selectedTest,
    })
  );
  const dispatch = useDispatch();
  //const history = useHistory();
  const [shoe, setshoe] = useState("");

  const [values, setValues] = useState(
    questionData || ({} as getQuestionResponse)
  );
  const [search, setSearch] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState("");

  useEffect(() => {
    setValues(questionData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionData]);

  return (
    <PageWrapper id="container">
      <ContainerWrapper>
        <FlexWrapper noPadding>
          <SectionTitle
            title="Manage Question"
            helpText={selectedTestName?.testName}
            hasBackButton
          />
        </FlexWrapper>
        <FlexWrapper justifyContent="end" noPadding>
          <Input
            value={search}
            onChange={(value: string) => {
              setSearch(value);
            }}
            width="25%"
            placeholder="Search"
          />
        </FlexWrapper>
        <div style={{ marginTop: "45px" }}>
          <TableWrapper>
            <Table
              size="sm"
              responsive="sm"
              borderless
              className="table table-striped"
            >
              <TableHeader>
                <TableRow>
                  {ViewQuestiontableHeader?.map((header, index) => (
                    <th key={`complete-session-header-${index}`}>{header}</th>
                  ))}
                </TableRow>
              </TableHeader>
              <tbody>
                {updateQuestin
                  // eslint-disable-next-line array-callback-return
                  .filter((listValue) => {
                    if (search === "") {
                      return listValue;
                    } else if (
                      listValue?.question
                        .toLowerCase()
                        .includes(search.toLowerCase())
                    ) {
                      return listValue;
                    }
                  })
                  .map((data, index) => (
                    <TableRow key={index}>
                      <td>{index + 1}</td>
                      <td>{data?.question}</td>
                      <td>{data?.answer === "null" ? "-" : data?.answer}</td>
                      <td>{data?.cat_name}</td>
                      <td>
                        <EditButton
                          onClick={() => {
                            setshoe(data?.question);
                            dispatch(
                              updateSelectedQuestion({
                                question: data?.question,
                                answer: data?.answer,
                                test_ID: data?.test_ID,
                                question_ID: data?.question_ID,
                              })
                            );
                          }}
                        >
                          Edit
                        </EditButton>
                        <DeleteButton
                          onClick={() => {
                            setShowDeleteModal(data?.question_ID);
                          }}
                        >
                          Delete
                        </DeleteButton>
                      </td>
                    </TableRow>
                  ))}
              </tbody>
            </Table>
          </TableWrapper>
        </div>
        {shoe && (
          <Modal
            title="Edit Question"
            handleCancel={() => {
              setshoe("");
            }}
            handleSubmit={() => {
              dispatch(
                editQuestion({
                  question: values?.question,
                  answer: values?.answer,
                  question_ID: values?.question_ID,
                })
              );
            }}
          >
            <FlexWrapper>
              <DropdownWrapper width="100%">
                <Input
                  boxShadow
                  value={values?.question || ""}
                  label="Question"
                  isRequired
                  onChange={(value: string) => {
                    setValues({ ...values, question: value });
                  }}
                />
              </DropdownWrapper>
              <DropdownWrapper width="100%">
                <Input
                  boxShadow
                  value={values?.answer || ""}
                  label="Answer"
                  isRequired
                  onChange={(value: string) => {
                    setValues({ ...values, answer: value });
                  }}
                />
              </DropdownWrapper>
            </FlexWrapper>
          </Modal>
        )}
        {showDeleteModal && (
          <DeleteModal
            title="Are you sure you want to delete this record?"
            handleCancel={() => setShowDeleteModal("")}
            handleSubmit={() => {
              dispatch(
                deleteQuestion({
                  id: showDeleteModal,
                })
              );
              setShowDeleteModal("");
            }}
          />
        )}
      </ContainerWrapper>
    </PageWrapper>
  );
};

export default QuestionTables;
