import { ReactElement } from "react";
import styled from "styled-components";
import { weight } from "../../const/fonts";
import { Button } from "react-bootstrap";
import { colors } from "../../const/theme";
import { UserFilterProps } from "./typings";
import {
  FlexWrapper,
  DropdownWrapper,
  Input,
  CountTitle,
} from "../../components";
import { PrimaryButton } from "../../components/Button";

export const PageWrapper = styled.div`
  width: 80%;
`;

export const TITLE = styled.p`
  font-size: 24px;
  font-weight: ${weight.bold};
  padding: 4%;
  padding-bottom: 2%;
`;

export const Icon = styled(Button)`
  border-color: ${colors.white};
  color: rgb(242, 127, 54);
  text-transform: capitalize;
  &:hover,
  &:focus {
    border-color: ${colors.white};
    background: ${colors.white};
    color: ${colors.black};
  }
  max-width: 40px;
`;

export const UserFilterSection = ({
  handleRoleChange,
  handleAdd,
  value,
  diasabled,
  title,
  count,
}: UserFilterProps): ReactElement => {
  return (
    <>
      <FlexWrapper>
        <CountTitle title={title} count={count} />
        {/* <DropdownWrapper>
          <Input
            placeholder={"Sample Stream"}
            value={value}
            name="ReferenceId"
            onBlur={() => {}}
            onChange={handleRoleChange}
          />
        </DropdownWrapper>
        <>
          <PrimaryButton
            onClick={handleAdd}
            style={{ marginTop: "1px" }}
            disabled={diasabled}
          >
            Submit
          </PrimaryButton>
        </> */}
      </FlexWrapper>
    </>
  );
};
