import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactElement } from "react";
import { Button } from "react-bootstrap";
import styled from "styled-components";
import { colors } from "../../../../../const/theme";
import { TestQuestionActionProps } from "./typings";

export const Icon = styled(Button)`
  border-color: ${colors.white};
  color: rgb(242, 127, 54);
  text-transform: capitalize;
  &:hover,
  &:focus {
    border-color: ${colors.white};
    background: ${colors.white};
    color: ${colors.black};
  }
  max-width: 40px;
`;

const QuestionManageActionWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const TestQuestionAction = ({
  handleEdit,
  handleDelete,
}: TestQuestionActionProps): ReactElement => {
  return (
    <QuestionManageActionWrapper>
      <Icon variant="outline-light" onClick={handleDelete}>
        <FontAwesomeIcon
          icon={["far", "trash-alt"]}
          style={{ marginRight: 10 }}
        />
      </Icon>
      <Icon variant="outline-light" onClick={handleEdit}>
        <FontAwesomeIcon icon={["far", "edit"]} style={{ marginRight: 10 }} />
      </Icon>
    </QuestionManageActionWrapper>
  );
};

export const DeleteButton = styled(Button)`
  background-color: red;
  color: ${colors.white};
  border: none;
  font-family: "Montserrat", sans-serif;
  &:hover,
  &:focus,
  &:hover,
  &:active {
    background: #e06b6b;
    color:red;
    border-color: ${({ theme }) => theme.button.primary};
  }
  margin: auto 5px;
  cursor: pointer;
  height: auto;
  font-size: 12px;
  @media (max-width: 500px) {
    margin: 0 auto;
    background-color: red;
  }
`;

export const EditButton = styled(Button)`
  background-color: #FF7900;
  color: ${colors.white};
  border: none;
  font-family: "Montserrat", sans-serif;
  &:hover,
  &:focus,
  &:hover,
  &:active {
    background: #df7d27;
    color:white;
    border-color: ${({ theme }) => theme.button.primary};
  }
  margin: auto 5px;
  cursor: pointer;
  height: auto;
  font-size: 12px;
  @media (max-width: 500px) {
    margin: 0 auto;
    background-color: #b38635;
  }
`;
