import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { deleteUser, getResult, getResultByMonth, getResultCount, getUserByMonth, getUsers, selectStreamInput } from "./api";
import { UserList, InitialState, ResultCount, resultResponse, dashBoardCount } from "./types";

const initialState: InitialState = {
  isLoading: false,
  users: [],
  usersList: null,
  selectStream: {
    Selectstream: "",
  },
  setSelectUser: null,
  formValues: {
    city: "",
    country: "",
    created_at: "",
    email: "",
    flag: "",
    id: "",
    last_name: "",
    mobile: "",
    modified_at: "",
    password: "",
    profile: "",
    state: "",
    user_name: "",
    stream: "",
  },
  count: {
    count: "",
  },
  getResultList: [],
  getDashboardResultCount: {
    success: '',
    message: ''
  },
  getDashboardUserCount: {
    success: '',
    message: ''
  }
};

export const userListSlice = createSlice({
  name: "userList",
  initialState,
  reducers: {
    updateHasError: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setSelectedUser: (state, action: PayloadAction<UserList | null>) => {
      state.setSelectUser = action.payload;
    },
    setFormValues: (state, action: PayloadAction<UserList>) => {
      state.formValues = initialState.formValues;
      state.formValues = action.payload;
    },
  },
  extraReducers: {
    [getUsers.pending.toString()]: (state) => {
      state.isLoading = true;
    },
    [getUsers.fulfilled.toString()]: (
      state,
      action: PayloadAction<Array<UserList>>
    ) => {
      state.isLoading = false;
      state.users = action?.payload;
    },
    [getUsers.rejected.toString()]: (state) => {
      state.isLoading = false;
    },
    [selectStreamInput.pending.toString()]: (state) => {
      state.isLoading = true;
    },
    [selectStreamInput.fulfilled.toString()]: (state) => {
      state.isLoading = false;
    },
    [selectStreamInput.rejected.toString()]: (state) => {
      state.isLoading = false;
    },
    [deleteUser.pending.toString()]: (state) => {
      state.isLoading = true;
    },
    [deleteUser.rejected.toString()]: (state) => {
      state.isLoading = false;
    },
    [deleteUser.fulfilled.toString()]: (state) => {
      state.isLoading = false;
    },
    [getResultCount.pending.toString()]: (state) => {
      state.isLoading = true;
    },
    [getResultCount.rejected.toString()]: (state) => {
      state.isLoading = false;
    },
    [getResultCount.fulfilled.toString()]: (
      state,
      action: PayloadAction<ResultCount>
    ) => {
      state.isLoading = false;
      state.count = action?.payload;
    },
    [getResult.fulfilled.toString()]: (
      state,
      action: PayloadAction<Array<resultResponse>>
    ) => {
      state.isLoading = false;
      state.getResultList = action?.payload;
    },
    [getResultByMonth.fulfilled.toString()]: (
      state,
      action: PayloadAction<dashBoardCount>
    ) => {
      state.isLoading = false;
      state.getDashboardResultCount = action?.payload;
    },
    [getUserByMonth.fulfilled.toString()]: (
      state,
      action: PayloadAction<dashBoardCount>
    ) => {
      state.isLoading = false;
      state.getDashboardUserCount = action?.payload;
    },
  },
});

export default userListSlice.reducer;
