import { createAsyncThunk } from "@reduxjs/toolkit";
import apiEndpoints from "../../const/apiEndpoints";
import api from "../../services";
import { assignCategoryList, AddAssignCategory, DeletePayload } from "./types";

export const handleGetAssignCategory = createAsyncThunk(
  "Assigncategories",
  async (): Promise<Array<assignCategoryList>> => {
    const response = await api.get(apiEndpoints.assignCategory);
    return response.data.message;
  }
);

export const addAssignCategoryInput = createAsyncThunk(
  "assigncategory/addCategorystream",
  async (
    requestPayload: AddAssignCategory,
    { dispatch }
  ): Promise<AddAssignCategory> => {
    const response = await api.post(
      apiEndpoints.addAssignCategory,
      requestPayload
    );

    if (response) {
      dispatch(handleGetAssignCategory());
    }

    return response.data;
  }
);

export const handleAssignDelete = createAsyncThunk(
  "Assigncategory/deleteAssign",
  async (requestPayload: DeletePayload, { dispatch }): Promise<Array<assignCategoryList>> => {
    const response = await api.post(
      `${apiEndpoints.deleteAssignedCategory}`,
      requestPayload
    );
    if (response) {
      dispatch(handleGetAssignCategory());
      alert('Deleted Succefully')
    }
    return response.data;
  }
);

