import { Switch, Route } from "react-router-dom";
import ROUTES from "./const/routes";
import Login from "./container/Login";
import ProtectedRoute from "./container/ProtectedRoute";
import {
  Dashboard,
  User,
  StreamMaster,
  AddCategory,
  AssignCategory,
  QuestionnaireManagement,
  StreamTables,
  CreateQuestion,
  QuestionTables,
  UserDetails,
  StreamDetails,
  AssignCategoryDetails,
  AddCategoryDetails,
  Tools,
  EditUser,
  CreateProductHeadQuestion,
  Result, 
} from "./pages";

const Routes = () => {
  return (
    <Switch>
      <Route exact path={ROUTES.LOGIN} component={Login} />
      <ProtectedRoute path={ROUTES.DASHBOARD} component={Dashboard} />
      <ProtectedRoute path={ROUTES.USER} component={User} />
      <ProtectedRoute path={ROUTES.STREAM} component={StreamMaster} />
      <ProtectedRoute path={ROUTES.ADD_CATEGORY} component={AddCategory} />
      <ProtectedRoute
        path={ROUTES.ASSIGN_CATEGORY}
        component={AssignCategory}
      />
      <ProtectedRoute
        path={ROUTES.QUESTIONNAIRE_MANAGEMENT}
        component={QuestionnaireManagement}
      />
      <ProtectedRoute
        path={ROUTES.CREATE_QUESTION}
        component={CreateQuestion}
      />
      <ProtectedRoute path={ROUTES.STREAM_TABLE} component={StreamTables} />
      <ProtectedRoute path={ROUTES.VIEW_QUESTION} component={QuestionTables} />
      <ProtectedRoute path={ROUTES.USER_DETAILS} component={UserDetails} />
      <ProtectedRoute path={ROUTES.STREAM_DETAILS} component={StreamDetails} />
      <ProtectedRoute
        path={ROUTES.ASSIGNCATEGORY_DETAILS}
        component={AssignCategoryDetails}
      />
      <ProtectedRoute
        path={ROUTES.ADDCATEGORY_DETAILS}
        component={AddCategoryDetails}
      />
      <ProtectedRoute path={ROUTES.TOOLS} component={Tools} />
      <ProtectedRoute path={ROUTES.EDIT_USER} component={EditUser} />
      <ProtectedRoute
        path={ROUTES.PRODUCT_HEAD}
        component={CreateProductHeadQuestion}
      />
      <ProtectedRoute path={ROUTES.RESULT} component={Result} /> 
    </Switch>
  );
};

export default Routes;
