/* eslint-disable array-callback-return */
import { ReactElement, SyntheticEvent, useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { UserFilterSection } from "./subcomponents";
import { resetValues, tableHeader } from "./const";
import {
  TableHeader,
  TableRow,
  TableWrapper,
  PageWrapper,
  SectionTitle,
  FlexWrapper,
  Loader,
  Input,
  Pagination,
  ContainerWrapper,
} from "../../components";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import strings from "../../locale/en";
import {
  addStreamMasterInput,
  handleStreamMaster,
} from "../../redux/streamMaster/api";
import { RootState } from "../../redux/store";
import { AddStreamMaster } from "../../redux/streamMaster/types";

const {
  user: {
    streamMaster: { title },
  },
} = strings;

const StreamMaster = (): ReactElement => {
  const dispatch = useDispatch();
  const { streamMaster, addStreamMaster, isLoading } = useSelector(
    (state: RootState) => ({
      streamMaster: state.streamList.streamMaster,
      addStreamMaster: state.streamList.selectStreamMaster as AddStreamMaster,
      isLoading: state.streamList.isLoading,
    }),
    shallowEqual
  );

  const [values, setValues] = useState(addStreamMaster);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [resetValuesState, setResetValuesState] = useState(resetValues);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [dataPerPage, setDataPerPage] = useState(10);

  //Get current data
  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = streamMaster.slice(indexOfFirstData, indexOfLastData);

  const clearValues = () => {
    setResetValuesState({
      stream: true,
    });
    setValues(addStreamMaster);
  };

  const canSave = !!values?.stream;

  useEffect(() => {
    dispatch(handleStreamMaster());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageWrapper>
      <ContainerWrapper>
        <FlexWrapper justifyContent="space-between" noPadding>
          <SectionTitle title="Add Stream" />
        </FlexWrapper>
        <UserFilterSection
          title="Total Stream"
          count={streamMaster.length}
          // handleRoleChange={(value: string) => {
          //   setValues({ ...values, stream: value });
          //   setResetValuesState(resetValues);
          // }}
          // handleAdd={(e: SyntheticEvent) => {
          //   e.preventDefault();
          //   dispatch(addStreamMasterInput(values));
          //   clearValues();
          // }}
          // value={values?.stream || ""}
          // diasabled={!canSave}
        />
        <FlexWrapper justifyContent="space-between">
          <SectionTitle title={title} />
          <Input
            value={searchTerm}
            width="25%"
            height="20"
            placeholder={"Search "}
            onChange={(value: string) => {
              setSearchTerm(value);
            }}
          />
        </FlexWrapper>

        {isLoading ? (
          <Loader />
        ) : (
          <div style={{ marginTop: "50px" }}>
            <TableWrapper>
              <Table
                size="sm"
                responsive="sm"
                borderless
                className="table table-striped"
              >
                <TableHeader>
                  <TableRow>
                    {tableHeader?.map((header, index) => (
                      <th key={`complete-session-header-${index}`}>{header}</th>
                    ))}
                  </TableRow>
                </TableHeader>
                <tbody>
                  {currentData
                    .filter((streamFilter) => {
                      if (searchTerm === "") {
                        return streamFilter;
                      } else if (
                        streamFilter.name
                          ?.toLowerCase()
                          .includes(searchTerm.toLowerCase())
                      ) {
                        return streamFilter;
                      }
                    })
                    .map((streamInfo, index) => {
                      const { name } = streamInfo;
                      return (
                        <TableRow key={`user-${index}`}>
                          <td style={{ padding: "10px" }}>
                            {index + 1 + indexOfFirstData}
                          </td>
                          <td>{name}</td>
                        </TableRow>
                      );
                    })}
                </tbody>
              </Table>
            </TableWrapper>
            <Pagination
              currentData={indexOfLastData}
              dataLimit={streamMaster.length}
              currentPage={currentPage}
              dataPerPage={dataPerPage}
              handleCurrentPage={setCurrentPage}
            />
          </div>
        )}
      </ContainerWrapper>
    </PageWrapper>
  );
};

export default StreamMaster;
