import { ReactElement } from "react";
import styled from "styled-components";
import { weight } from "../../../const/fonts";
import { Button, Card, Modal } from "react-bootstrap";
import { colors } from "../../../const/theme";
import { UserlistActionProps, UserFilterProps } from "./typings";
import {
  FlexWrapper,
  DropdownWrapper,
  Dropdown, 
} from "../../../components";
import { PrimaryButton } from "../../../components/Button";
import { ViewButton } from "../../Questioniar/AddQuestion/createTest/subcomponents";

export const PageWrapper = styled.div`
  width: 80%;
`;

export const TITLE = styled.p`
  font-size: 24px;
  font-weight: ${weight.bold};
  padding: 4%;
  padding-bottom: 2%;
`;

const UserlistWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const Icon = styled(Button)`
  border: none;
  color: rgb(242, 127, 54);
  background: none;
  text-transform: capitalize;
  &:hover,
  &:focus {
    border-color: none;
    background: none;
    color: ${colors.black};
  }
  max-width: 40px;
`;

export const UserFilterSection = ({
  handleSearch,
  assignList,
  categoryList,
  handleAssignSelect,
  handleStreamSelect,
  streamRef,
  categoryRef,
  buttonDisabled, 
}: UserFilterProps): ReactElement => {
  return (
    <>
      <FlexWrapper> 
        <DropdownWrapper width="100%">
          <Dropdown
            isRequired
            dropdownList={assignList}
            singleSelect
            placeholder={"Select Stream"}
            handleSelect={handleStreamSelect}
            ref={streamRef}
          />
        </DropdownWrapper>
        <DropdownWrapper  width="100%">
          <Dropdown
            isRequired
            dropdownList={categoryList}
            placeholder={"Select  Category"}
            handleSelect={handleAssignSelect}
            ref={categoryRef}
          />
        </DropdownWrapper>
        <>
          <PrimaryButton
            style={{ marginTop: "2px" }}
            onClick={handleSearch}
            disabled={buttonDisabled}
          >
            Assign
          </PrimaryButton>
        </>
      </FlexWrapper>
    </>
  );
};

export const UserlistAction = ({
  handleView,
  disabled,
}: UserlistActionProps): ReactElement => {
  return (
    <UserlistWrapper>
      <ViewButton onClick={handleView} disabled={disabled}>
        View
      </ViewButton>
    </UserlistWrapper>
  );
};

export const BootstrapModal = styled(Modal)`
  display: flex !important;
`;
export const Wrapper = styled.div`
  text-align: center;
  display: block;
  justify-content: center;
  align-items: center;
  height: auto;
  width: auto;
  border-radius: 20px;
  border: none;
  @media (max-width: 900px) {
    width: auto;
  }
  @media (max-width: 415px) {
    width: auto;
  }
`;

export const CardWrapper = styled(Card)`
  box-shadow: 0 1px 10px 0 ${({ theme }) => theme.card.border};
  width: ${({ width }) => (width ? `${width}%` : "56%")};
  padding: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  text-align: center;
  margin: 0 auto 20px;
  @media (max-width: 415px) {
    width: 100%;
    margin-bottom: 16px;
  }
`;
