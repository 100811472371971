import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { addCategoryInput, addTools, deleteAddCategory, getCourse, getCourseByStreamId, getTools, handleGetCategory } from "./api";
import { InitialState, categoryList, GetTools, getCourseprops, ToolsResponse, Response, getSelectedStream, getCatergorybySteram } from "./types";

const initialState: InitialState = {
  isLoading: false,
  categories: [],
  categoryList: null,
  selectcategoryMaster: {
    category: "",
    courseName: { course: [] },
    toolList: { tools: [] },
    description: "",
    is_special: false
  },
  toolsList: [],
  addtool: {
    toolName: ''
  },
  selectedcategoryDetails: undefined,
  selectedToolList: null,
  getCourseArray: [],
  toolsResponse: {
    message: '',
    success: ''
  },
  addCat: {
    message: '',
    success: ''
  },
  updateSlectedStreamById: {
    streamId: ''
  },
  getCategoryBustream: []
};
export const addcategorySlice = createSlice({
  name: "addCategories",
  initialState,
  reducers: {
    updateHasError: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    resetAddCategory: (state) => {
      state.selectcategoryMaster = null;
      state.categories = [];
    },
    updateSelectedCategory: (state, action: PayloadAction<categoryList | null>) => {
      state.selectedcategoryDetails = action.payload;
    },
    updateSelectedTools: (state, action: PayloadAction<GetTools | null>) => {
      state.selectedToolList = action.payload;
    },
    updateSelectStream: (state, action: PayloadAction<getSelectedStream | null>) => {
      state.updateSlectedStreamById = action.payload;
    },
  },
  extraReducers: {
    [handleGetCategory.pending.toString()]: (state) => {
      state.isLoading = true;
    },
    [handleGetCategory.fulfilled.toString()]: (
      state,
      action: PayloadAction<Array<categoryList>>
    ) => {
      state.isLoading = false;
      state.categories = action?.payload;
    },
    [handleGetCategory.rejected.toString()]: (state) => {
      state.isLoading = false;
    },
    [addCategoryInput.pending.toString()]: (state) => {
      state.isLoading = true;
    },
    [addCategoryInput.fulfilled.toString()]: (
      state,
      action: PayloadAction<Response>
    ) => {
      state.addCat = action?.payload
    },
    [addCategoryInput.rejected.toString()]: (state) => {
      state.isLoading = false;
    },
    [getTools.fulfilled.toString()]: (
      state,
      action: PayloadAction<Array<GetTools>>
    ) => {
      state.isLoading = false;
      state.toolsList = action?.payload
    },
    [deleteAddCategory.fulfilled.toString()]: (state) => {
      state.isLoading = false
    },
    [getCourse.fulfilled.toString()]:
      (
        state,
        action: PayloadAction<Array<getCourseprops>>
      ) => {
        state.getCourseArray = action?.payload
      },
    [addTools.fulfilled.toString()]: (
      state,
      action: PayloadAction<ToolsResponse>
    ) => {
      state.toolsResponse = action?.payload
    },
    [getCourseByStreamId.fulfilled.toString()]: (
      state,
      action: PayloadAction<Array<getCatergorybySteram>>
    ) => {
      state.getCategoryBustream = action?.payload
    }
  },
});

export default addcategorySlice.reducer;
