import { ReactElement } from "react";
import styled from "styled-components";

interface CountProps {
  title?: string;
  count?: number;
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;
const Count = styled.div`
  display: flex;
  justify-content: center;
  color: #ff7900;
  font-family: "Montserrat", sans-serif;
  font-weight: 670;
  font-size: 18px;
  margin-top: 1;
`;

const Title = styled.div`
  color: #141a45;
  font-weight: 450;
  padding: 5px;
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
`;

export const CountTitle = ({ title, count }: CountProps): ReactElement => {
  return (
    <Wrapper>
      <Title>{title} : </Title>
      <Count>{count}</Count>
    </Wrapper>
  );
};
