import { ReactElement } from 'react'
import { Modal as BootstrapModal } from 'react-bootstrap'
import { colors } from '../../const/theme'
import { Body, H3 } from '../../typography'
import ModalProps from './typings'
import Buttons from './subcomponent'
import { PrimaryButton } from '../Button'

const Modal = ({
  title,
  isLargeModal,
  handleCancel,
  handleSubmit,
  children,
  submitButtonText,
  cancelButtonText,
}: ModalProps): ReactElement => {
  return (
    <BootstrapModal
      show={true}
      onHide={handleCancel}
      size={isLargeModal ? 'lg' : 'sm'}
      centered={true}
      backdrop='static'
      keyboard={false}
    >
      <BootstrapModal.Header closeButton>
        {title && <H3 style={{ fontWeight: '600' }} color={colors.purple}>{title}</H3>}
      </BootstrapModal.Header>

      <BootstrapModal.Body>
        <Body>  {children && children}</Body>
      </BootstrapModal.Body>
      <BootstrapModal.Footer>
        <Buttons variant="secondary" onClick={handleCancel}>
          {cancelButtonText || 'Cancel'}
        </Buttons>
        <PrimaryButton onClick={handleSubmit}>
          {submitButtonText || 'Submit'}
        </PrimaryButton>
      </BootstrapModal.Footer>
    </BootstrapModal>
  )
}

export default Modal
