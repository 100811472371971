import styled from "styled-components";
import { weight } from "../../../../const/fonts";

interface InputProps {
  width?: string
}

export const TITLE = styled.p`
  font-size: 20px;
  font-weight: ${weight.normal};
  font-family: "Montserrat", sans-serif;
  margin: 10px;
`;

export const InputWrapper = styled.div<InputProps>`
margin:5px;
  width: ${({ width }) => width || '45%'};
  @media (max-width: 500px) {
    width: 100%;
    padding: 10px;
  }
`;

export const DropdownWrapper = styled.div`
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: row;
  @media (max-width: 500px) {
    width: 100%;
  }
`;

export const AddQuestionWrapper = styled.div`
  width: 100%;
  display: flex;
  height: 100px;
  margin-bottom:-45px;
  justify-content: space-evenly;
  @media (max-width: 500px) {
    display: flex;
    flex-direction: column;
  }
`;





export const QuestionWrapper = styled.div`
width:100%;
margin: 12px 0;
display: flex;
flex-wrap: wrap;
justify-content: space-between;
`


export const WrapperValue = styled.div`
   width: 40%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  @media (max-width: 500px) {
    width: 100%;
  }
`
export const OptionWrapper = styled.div` 
width: 100%;
padding-left: 10px;
display: block;
`