import { ReactElement, useEffect, useState } from "react";
import strings from "../../locale/en";
import { useHistory } from "react-router-dom";
import {
  ContainerWrapper,
  FlexWrapper,
  Input,
  Loader,
  PageWrapper,
  Pagination,
  SectionTitle,
  TableHeader,
  TableRow,
  TableWrapper,
} from "../../components";
import { Table } from "react-bootstrap";
import { QuestionManagementtableHeader } from "./const";
import { QuestionCreateButton } from "./subcomponent";
import ROUTES from "../../const/routes";
import { RootState } from "../../redux/store";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { handleStreamMaster } from "../../redux/streamMaster/api";
import {
  getTest,
  updateSelectedStream,
} from "../../redux/QuestionaryManagement/actions";

const {
  user: {
    questionManagement: { title },
  },
} = strings;

const QuestionnaireManagement = (): ReactElement => {
  const {
    streams: { isLoading },
    stream,
  } = useSelector(
    (state: RootState) => ({
      stream: state.streamList.streamMaster,
      streams: state.streamList,
    }),
    shallowEqual
  );
  const history = useHistory();
  const dispatch = useDispatch();

  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [dataPerPage, setDataPerPage] = useState(10);

  //Get current data
  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = stream.slice(indexOfFirstData, indexOfLastData);

  useEffect(() => {
    dispatch(handleStreamMaster());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageWrapper>
      <ContainerWrapper>
        <FlexWrapper justifyContent="space-between">
          <SectionTitle title={title} />
          <Input
            value={search}
            width="25%"
            placeholder="Search"
            onChange={(value: string) => {
              setSearch(value);
            }}
          />
        </FlexWrapper>
        {isLoading ? (
          <Loader />
        ) : (
          <div style={{ marginTop: "45px" }}>
            <TableWrapper>
              <Table
                size="sm"
                responsive="sm"
                borderless
                className="table table-striped"
              >
                <TableHeader>
                  <TableRow>
                    {QuestionManagementtableHeader?.map((header, index) => (
                      <th key={`complete-session-header-${index}`}>{header}</th>
                    ))}
                  </TableRow>
                </TableHeader>
                <tbody>
                  {currentData
                    // eslint-disable-next-line array-callback-return
                    .filter((streamValues) => {
                      if (search === "") {
                        return streamValues;
                      } else if (
                        streamValues?.name
                          .toLowerCase()
                          .includes(search.toLowerCase())
                      ) {
                        return streamValues;
                      }
                    })
                    .map((x, index) => {
                      return (
                        <TableRow key={index}>
                          <td>{index + 1 + indexOfFirstData}</td>
                          <td>{x.name}</td>
                          <td>
                            <QuestionCreateButton
                              handleGetById={() => {
                                dispatch(
                                  updateSelectedStream({
                                    streamID: x.id,
                                    streamName: x.name,
                                  })
                                );
                                dispatch(
                                  getTest({
                                    streamID: x.id,
                                  })
                                );
                                history.push({
                                  pathname: ROUTES.STREAM_TABLE,
                                });
                              }}
                            />
                          </td>
                        </TableRow>
                      );
                    })}
                </tbody>
              </Table>
            </TableWrapper>
            <Pagination
              currentData={indexOfLastData}
              dataLimit={stream.length}
              currentPage={currentPage}
              dataPerPage={dataPerPage}
              handleCurrentPage={setCurrentPage}
            />
          </div>
        )}
      </ContainerWrapper>
    </PageWrapper>
  );
};
export default QuestionnaireManagement;
