import { Button } from "react-bootstrap";
import styled from "styled-components"; 

export const PageDetails = styled.div`
  font-family: "Montserrat", sans-serif;
  width: 20%;
  text-align: center;
  #page {
    display: flex;
    justify-content: center;
    font-size: 14px;
    margin-top: 15px;
    font-weight: 550;
  }
  @media (max-width: 415px) {
    width: 40%;
  }
`;
export const PaginationWrapper = styled.div`
  width: 100%;
  height: 55px;
  display: flex;  
  flex-direction: row; 
  justify-content: center;
`;
export const NextButton = styled(Button)`
  margin: auto;
  margin-top: auto;
  margin-left: auto;
  background-color: #3335cf;
  border: none;
  font-size: 13px;
  font-family: "Montserrat", sans-serif;
  &:hover {
    box-shadow: 1px 2px 10px #4e50b8;
  }
`;

export const PreviousButton = styled(Button)`
  margin: auto;
  margin-top: auto;
  margin-left: auto;
  background-color: #3335cf;
  border: none;
  font-size: 13px;
  font-family: "Montserrat", sans-serif;
  &:hover {
    box-shadow: 1px 2px 10px #4e50b8;
  }
`;
