import {
  getTest,
  createQuestion,
  CreateTestApi,
  getLevels,
  getAssignCategoryById,
} from "./api";
import { questionaryManagementSlice } from "./reducer";

const { updateSelectedStream, updateSelectedTest,updateSelectedQuestion } =
  questionaryManagementSlice.actions;

export {
  getTest,
  createQuestion,
  CreateTestApi,
  getLevels,
  updateSelectedStream,
  updateSelectedTest,
  getAssignCategoryById,
  updateSelectedQuestion
};
