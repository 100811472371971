import { getUsers, selectStreamInput, deleteUser, getResultCount } from "./api";
import { userListSlice } from "./reducer";

const { updateHasError, setSelectedUser, setFormValues } =
  userListSlice.actions;

export {
  getUsers,
  selectStreamInput,
  updateHasError,
  setSelectedUser,
  deleteUser,
  setFormValues,
  getResultCount,
};
