const apiEndpoints = {
  login: "Login",
  getUser: "Get_Users",
  getStream: "Get_Stream",
  addStream: "Add_Stream",
  logout: "Logout",
  addCategory: "Add_Category",
  getCategory: "Get_Category",
  assignCategory: "Get_AssignCategory",
  addAssignCategory: "Assign_Category",
  getTest: "Get_Test",
  CreateQuestion: "Create_Question",
  CreateTest: "Create_Test",
  getUserById: "Get_Users_byID",
  deleteAssignedCategory: "Delete_Assign_Category",
  getLevels: "Get_Level",
  getAssignCategoryById: "Get_Assign_CategoryById",
  deleteUser: "Delete_User",
  deleteStream: "Delete_Stream",
  getTools: "Get_Tools",
  addtools: "Create_Tools",
  editUser: "Edit_User",
  deleteCategory: "Delete_Category",
  editTools: "Edit_Tools",
  editCategory: "Edit_Category",
  getResultCount: "Get_result_count",
  getQuestion: 'Get_Question_Admin',
  getCourse: 'Get_Courses',
  editQuestion: 'Edit_Question',
  deleteQuestion: 'Delete_Question',
  getResult: 'Get_result',
  deleteTest: 'Delete_Test',
  getResultByMonth: 'Get_ResultCountByMonth',
  getUserByMonth: 'Get_UserCountByMonth',
  getCourseByStreamId: 'Get_Assign_CategoryById'
};

export default apiEndpoints;
