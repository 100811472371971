import { ReactElement, useEffect, useState, useRef } from "react";
import {
  TITLE,
  DropdownWrapper,
  AddQuestionWrapper,
  InputWrapper,
} from "./subcomponents";
import { useHistory } from "react-router-dom";
import {
  FlexWrapper,
  PageWrapper,
  BackButton,
  SectionTitle,
  Dropdown,
  Input,
  ContainerWrapper,
} from "../../../../components";
import { trueORfalse } from "../../../../const/dropdown";
import { useDispatch, useSelector } from "react-redux";
import { createQuestion } from "../../../../redux/QuestionaryManagement/actions";
import { RootState } from "../../../../redux/store";
import {
  CreateTestProps,
  CreateTestResponse,
} from "../../../../redux/QuestionaryManagement/types";
import { Form } from "react-bootstrap";
import { PrimaryButton, SecondaryButton } from "../../../../components/Button";
import { getCategoryByStreamDropdown } from "../../../../helpers/dropdown";
import { getCourseByStreamId } from "../../../../redux/AddCategory/api";
import { DropdownListProps } from "../../../../components/EditableDropdown/typings";
import { AddQuestionField, formValues } from "./typings";
import validateAddQuestionsForm from "./helper";
import { hasFormError } from "../../../../helpers/formValidation";
import { handleStreamMaster } from "../../../../redux/streamMaster/api";
import ROUTES from "../../../../const/routes";

const CreateQuestion = (): ReactElement => {
  const history = useHistory();
  const dispatch = useDispatch();
  const answerRef = useRef(null);
  const catgoryRef = useRef(null);
  const { selectedStream, selectedTest, categoryData, updateSlectedStreamById } = useSelector(
    (state: RootState) => ({
      categoryData: state.categorylist.getCategoryBustream,
      selectedStream: state.questionaryManagement.selectedStream,
      selectedTest: state.questionaryManagement.selectedTest,
      createTestResponse: state.questionaryManagement
        .selectedQuestion as CreateTestResponse,
      updateSlectedStreamById: state.categorylist.updateSlectedStreamById
    })
  ); 

  const CategoryDropdown = getCategoryByStreamDropdown(categoryData)

  const [values, setValues] = useState<Array<CreateTestProps>>([]);
  const [errors, setErrors] = useState({} as Record<string, string>);
  const [formValues, setFormValues] = useState<formValues>({} as formValues);

  const addFormfields = () => {
    const validationError = validateAddQuestionsForm({
      formValues,
      errors,
    });
    if (hasFormError(validationError)) {
      setErrors(validationError);
    } else {
      setValues([
        ...values,
        { ...formValues, ...selectedStream, ...selectedTest },
      ]);
      setFormValues({} as formValues);
      resetValues();
    }
  };

  const handleRemove = (i: number) => {
    const value = [...values];
    value.splice(i, 1);
    setValues(value);
  };

  const resetValues = () => {
    answerRef?.current?.resetSelectedValues();
    catgoryRef?.current?.resetSelectedValues();
  };

  const validateField = (field: AddQuestionField): void => {
    setErrors(
      validateAddQuestionsForm({
        formValues,
        errors,
        field,
      })
    );
  };

  const handleSubmit = () => {
    const validationError = validateAddQuestionsForm({
      formValues,
      errors,
    });

    if (hasFormError(validationError)) {
      setErrors(validationError);
    } else {
      dispatch(
        createQuestion({
          isMultiOption: "false",
          question: [
            ...values,
            { ...formValues, ...selectedStream, ...selectedTest },
          ],
        })
      );
      alert("Added Succesfully");
    }
    history.push(ROUTES.STREAM_TABLE);
  };

  useEffect(() => {
    dispatch(getCourseByStreamId({
      streamID: updateSlectedStreamById?.streamId
    }));
    dispatch(handleStreamMaster());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageWrapper> 
      <ContainerWrapper>
        <FlexWrapper hasBorder>
          <BackButton handleBack={() => history.goBack()} />
          <SectionTitle title={selectedTest?.testName} />
        </FlexWrapper>
        <TITLE>{"Add Question"}</TITLE>
        <>
          <Form>
            <FlexWrapper>
              {values.map((element, index) => (
                <>
                  <AddQuestionWrapper key={index}>
                    <InputWrapper>
                      <Input
                        inputType="text"
                        isDisabled={true}
                        value={element.question}
                        width="60"
                      />
                    </InputWrapper>
                    <InputWrapper>
                      <Input
                        inputType="text"
                        isDisabled={true}
                        value={element.answer}
                      />
                    </InputWrapper>
                    <InputWrapper>
                      <Input
                        inputType="text"
                        isDisabled={true}
                        value={element.categoryName}
                      />
                    </InputWrapper>
                    <PrimaryButton style={{ marginTop: '1px' }} onClick={() => handleRemove(index)}>
                      Clear
                    </PrimaryButton>
                  </AddQuestionWrapper>
                </>
              ))}
              <>
                <AddQuestionWrapper>
                  <InputWrapper>
                    <Input
                      placeholder={"Add Question.."}
                      inputType="text"
                      value={formValues.question || ""}
                      name="Title"
                      width="60"
                      onBlur={() => validateField("question")}
                      error={errors.question}
                      onChange={(value: string) => {
                        setFormValues({ ...formValues, question: value });
                      }}
                    />
                  </InputWrapper>
                  <DropdownWrapper>
                    <Dropdown
                      isRequired
                      ref={answerRef}
                      singleSelect
                      dropdownList={trueORfalse}
                      error={errors.answer}
                      placeholder={"Select ans.."}
                      handleSelect={([value]: Array<DropdownListProps>) => {
                        setFormValues({ ...formValues, answer: value.name });
                        setErrors({ ...errors, answer: "" });
                      }}
                    />
                  </DropdownWrapper>
                  <DropdownWrapper>
                    <Dropdown
                      isRequired
                      singleSelect
                      ref={catgoryRef}
                      error={errors.categoryName}
                      dropdownList={CategoryDropdown}
                      placeholder={"Category.."}
                      handleSelect={([value]: Array<DropdownListProps>) => {
                        setFormValues({
                          ...formValues,
                          categoryName: value.name,
                          categoryID: value.id,
                        });
                        setErrors({ ...errors, categoryName: "" });
                      }}
                    />
                  </DropdownWrapper>
                </AddQuestionWrapper>
              </>
            </FlexWrapper>
          </Form>
          <FlexWrapper justifyContent="center">
            <PrimaryButton onClick={handleSubmit}>Submit</PrimaryButton>
            <SecondaryButton type="button" onClick={addFormfields}>
              Add More
            </SecondaryButton>
          </FlexWrapper>
        </>
      </ContainerWrapper>
    </PageWrapper>
  );
};

export default CreateQuestion;
