import { ReactElement, useEffect, useState } from "react";
import {
  ContainerWrapper,
  FlexWrapper,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  Input,
  Loader,
  PageWrapper,
  SectionTitle,
  TableHeader,
  TableRow,
  TableWrapper,
} from "../../../../components";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { tableHeader } from "../../const";
import { Table } from "react-bootstrap";
import ROUTES from "../../../../const/routes";
import {
  QuestionManageAction,
  BootstrapModal,
  CreateTestFilterSection,
} from "./subcomponents";
import { CreateTest } from "../../../../redux/QuestionaryManagement/types";
import {
  CreateTestApi,
  updateSelectedTest,
} from "../../../../redux/QuestionaryManagement/actions";
import {
  updateSelectStream
} from "../../../../redux/AddCategory/action";
import {
  deleteTest,
  getLevels,
  GetQuestionApi,
} from "../../../../redux/QuestionaryManagement/api";
import { getLevelsDropdown } from "../../../../helpers/dropdown";
import { DropdownListProps } from "../../../../components/EditableDropdown/typings";
import { PrimaryButton } from "../../../../components/Button"; 

const StreamTables = (): ReactElement => {
  const {
    test: { isLoading },
    testList,
    selectedStream,
    createTest,
    levelList, 
  } = useSelector((state: RootState) => ({
    test: state.questionaryManagement,
    testList: state.questionaryManagement.getTestList,
    selectedStream: state.questionaryManagement.selectedStream,
    createTest: state.questionaryManagement.selectCreateTest as CreateTest,
    levelList: state.questionaryManagement.getLevelList, 
  }));
  const history = useHistory();

  const LevelDropdown = levelList ? getLevelsDropdown(levelList) : [];

  const [showModal, setShowModal] = useState(false);
  const [values, setValues] = useState(createTest);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getLevels()); 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const RedirectTo = (value: string) => {
    if (value === "expert") {
      history.push(ROUTES.CREATE_QUESTION);
    } else {
      history.push(ROUTES.PRODUCT_HEAD);
    }
  };
  return (
    <PageWrapper id="container">
      <ContainerWrapper>
        <FlexWrapper justifyContent="space-between" hasBorder noPadding>
          <SectionTitle title="Test Lists" hasBackButton />
          <div>
            <PrimaryButton onClick={() => setShowModal(true)}>
              Create Test
            </PrimaryButton>
          </div>
        </FlexWrapper>
        {isLoading ? (
          <Loader />
        ) : (
          <div>
            <TableWrapper>
              <Table
                size="sm"
                responsive="sm"
                borderless
                className="table table-striped"
              >
                <TableHeader>
                  <TableRow>
                    {tableHeader?.map((header, index) => (
                      <th key={`complete-session-header-${index}`}>{header}</th>
                    ))}
                  </TableRow>
                </TableHeader>
                <tbody>
                  {testList
                    // eslint-disable-next-line array-callback-return
                    .filter((listValues) => {
                      if (search === "") {
                        return listValues;
                      } else if (
                        listValues?.stream_name
                          .toLowerCase()
                          .includes(search.toLowerCase()) ||
                        listValues?.test_name
                          .toLowerCase()
                          .includes(search.toLowerCase()) ||
                        listValues?.test_level
                          .toLowerCase()
                          .includes(search.toLowerCase())
                      ) {
                        return listValues;
                      }
                    })
                    .map((data, index) => {
                      return (
                        <TableRow key={index}>
                          <td>{index + 1}</td>
                          <td>{data.test_name}</td>
                          <td>{data.stream_name}</td>
                          <td>{data.test_level}</td>
                          <td>
                            <QuestionManageAction
                              handleDelete={() => {
                                dispatch(
                                  deleteTest({
                                    id: data?.test_ID
                                  })
                                )
                              }}
                              handleView={() => {
                                history.push(ROUTES.VIEW_QUESTION);
                                dispatch(
                                  GetQuestionApi({
                                    testID: data?.test_ID,
                                  })
                                );
                                dispatch(
                                  updateSelectedTest({
                                    test_ID: data.test_ID,
                                    testName: data.test_name,
                                  })
                                );
                              }}
                            />
                          </td>
                          <td>
                            <PrimaryButton
                              onClick={() => {
                                dispatch(
                                  updateSelectedTest({
                                    test_ID: data.test_ID,
                                    testName: data.test_name,
                                  })
                                );
                                dispatch(updateSelectStream({
                                  streamId: data?.stream_id
                                }))
                                RedirectTo(data.test_level);
                              }}
                            >
                              Add question
                            </PrimaryButton>
                          </td>
                        </TableRow>
                      );
                    })}
                </tbody>
              </Table>
            </TableWrapper>
          </div>
        )}
        {showModal && (
          <BootstrapModal
            handleCancel={() => setShowModal(false)}
            handleSubmit={() => {
              dispatch(CreateTestApi({ ...values, ...selectedStream }));
              setShowModal(false);
            }}
            isLargeModal={true}
            title={"Select Test"}
          >
            <CreateTestFilterSection
              handleTestChange={(value: string) => {
                setValues({ ...values, testName: value });
              }}
              testvalue={values?.testName || ""}
              handleLevelSelect={(value: DropdownListProps) => {
                setValues({
                  ...values,
                  testLevel: value?.name,
                  testLevelID: value?.id,
                });
              }}
              levelList={LevelDropdown}
            />
          </BootstrapModal>
        )}
      </ContainerWrapper>
    </PageWrapper>
  );
};

export default StreamTables;
