import { ReactElement } from "react";
import styled from "styled-components";
import { EditableDropdown, Input, Modal } from "../../../../components";

import {
  CreateQuestionProps,
  CreateTest,
  QuestionManageActionProps,
} from "./typings";
import { Button } from "react-bootstrap";
import { colors } from "../../../../const/theme";
import { PrimaryButton } from "../../../../components/Button";

export const PlusButton = styled(Button)`
  width: 40px;
  height: 40px;
  background-color: #f47f34;
  border: none;
  margin-top: -1px;
  margin-left: 4px;
  &:hover,
  &:active,
  &:focus {
    background-color: #f47f34;
  }
`;

export const StyledCardWrapper = styled.div`
  margin-left: 10%;
  width: 50%;
  position: absolute;
  z-index: 1000;
`;

export const AddRow = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-around;
`;

export const Icon = styled(Button)`
  border: none;
  color: rgb(242, 127, 54);
  background: none;
  text-transform: capitalize;
  &:hover,
  &:focus {
    border-color: none;
    background: none;
    color: ${colors.black};
  }
  max-width: 40px;
`;

const QuestionManageActionWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const DeleteButton = styled(Button)`
  background-color: red;
  color: ${colors.white};
  border: none;
  font-family: "Montserrat", sans-serif;
  &:hover,
  &:focus,
  &:hover,
  &:active {
    background: #e06b6b;
    color: red;
    border-color: ${({ theme }) => theme.button.primary};
  }
  margin: auto 5px;
  cursor: pointer;
  height: auto;
  font-size: 12px;
  @media (max-width: 500px) {
    margin: 0 auto;
    background-color: red;
  }
`;

export const ViewButton = styled(Button)`
  background-color: #FF7900;
  color: ${colors.white};
  border: none;
  font-family: "Montserrat", sans-serif;
  &:hover,
  &:focus,
  &:hover,
  &:active {
    background: rgb(242, 127, 54);
    color: white;
    border-color: ${({ theme }) => theme.button.primary};
  }
  margin: auto 5px;
  cursor: pointer;
  height: auto;
  font-size: 12px;
  @media (max-width: 500px) {
    margin: 0 auto;
    background-color: red;
  }
`;

export const QuestionCreateButton = ({ handleCreate }: CreateQuestionProps) => {
  return <PrimaryButton onClick={handleCreate}>Create Test</PrimaryButton>;
};

export const QuestionManageAction = ({
  handleView,
  handleDelete,
}: QuestionManageActionProps): ReactElement => {
  return (
    <QuestionManageActionWrapper>
      <ViewButton onClick={handleView}>View</ViewButton>
      <DeleteButton onClick={handleDelete} >Delete</DeleteButton>
    </QuestionManageActionWrapper>
  );
};

export const BootstrapModal = styled(Modal)`
  display: flex !important;
`;

export const TestWrapper = styled.div`
  height: 12rem;
  width: 60%;
  margin: 0 auto;
`;
export const ModalInput = styled.div`
  margin-bottom: 2rem;
`;
export const CreateTestFilterSection = ({
  handleLevelSelect,
  handleTestChange,
  testvalue,
  levelList,
}: CreateTest): ReactElement => {
  return (
    <>
      <TestWrapper>
        <ModalInput>
          <Input
            value={testvalue}
            boxShadow
            label="Enter Test Name"
            onChange={handleTestChange}
          />
        </ModalInput>
        <EditableDropdown
          dropdownList={levelList}
          title="Select Level"
          placeholder={"Select"}
          handleSelect={handleLevelSelect}
        />
      </TestWrapper>
    </>
  );
};
