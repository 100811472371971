import { ReactElement, useEffect, useState, useRef } from "react";
import {
  TITLE,
  DropdownWrapper,
  AddQuestionWrapper,
  InputWrapper,
  QuestionWrapper,
  WrapperValue,
  OptionWrapper,
} from "./subcomponents";
import { useHistory } from "react-router-dom";
import {
  FlexWrapper,
  PageWrapper,
  BackButton,
  SectionTitle,
  Dropdown,
  Input,
  ContainerWrapper,
} from "../../../../components";
import { useDispatch, useSelector } from "react-redux";
import { createQuestion } from "../../../../redux/QuestionaryManagement/actions";
import { RootState } from "../../../../redux/store";
import { ProductHeadProps } from "../../../../redux/QuestionaryManagement/types";
import { Form } from "react-bootstrap";
import { PrimaryButton, SecondaryButton } from "../../../../components/Button";
import { getCategoryDropdown } from "../../../../helpers/dropdown";
import { handleGetCategory } from "../../../../redux/AddCategory/api";
import { DropdownListProps } from "../../../../components/EditableDropdown/typings";
import {
  AddQuestionFieldProjectHead,
  formValues,
  OptionsProps,
} from "./typings";
import { handleStreamMaster } from "../../../../redux/streamMaster/api";
import ROUTES from "../../../../const/routes";
import { IsMultipleDP } from "./const";
import validateAddQuestionsFormProjectHead from "./helper";
import {
  hasFormError,
  validateRequired,
} from "../../../../helpers/formValidation";
import { trueORfalse } from "../../../../const/dropdown";

const CreateProductHeadQuestion = (): ReactElement => {
  const history = useHistory();
  const dispatch = useDispatch();
  const answerRef = useRef(null);
  const catgoryRef = useRef(null);
  const isMultipleRef = useRef(null);

  const { selectedStream, selectedTest, categoryData } = useSelector(
    (state: RootState) => ({
      categoryData: state.categorylist.categories,
      selectedStream: state.questionaryManagement.selectedStream,
      selectedTest: state.questionaryManagement.selectedTest,
    })
  );

  const CategoryDropdown = categoryData
    ? getCategoryDropdown(categoryData)
    : [];

  const [values, setValues] = useState<Array<ProductHeadProps>>([]);
  const [errors, setErrors] = useState({} as Record<string, string>);
  const [optionErrors, setoptionErrors] = useState("");
  const [formValues, setFormValues] = useState<formValues>({} as formValues);
  const [Option, setOption] = useState("");
  const [value2, setValue2] = useState<Array<OptionsProps>>([]);
  const [answerError, setAnswerError] = useState("");

  const addFormfields = () => {
    const validationError = validateAddQuestionsFormProjectHead({
      formValues,
      errors,
    });
    if (hasFormError(validationError)) {
      setErrors(validationError);
    } else {
      setValues([
        ...values,
        {
          options: [...value2, { option: Option }],
          ...formValues,
          ...selectedStream,
          ...selectedTest,
        },
      ]);
      setFormValues({} as formValues);
      setValue2([]);
      setOption("");
      resetValues();
    }
  };

  const addOptions = () => {
    setValue2([...value2, { option: Option }]);
    setOption("");
  };

  const handleRemove = (i: number) => {
    const value = [...values];
    value.splice(i, 1);
    setValues(value);
  };

  const handleOptionRemove = (i: number) => {
    const value = [...value2];
    value.splice(i, 1);
    setValue2(value);
  };

  const resetValues = () => {
    answerRef?.current?.resetSelectedValues();
    catgoryRef?.current?.resetSelectedValues();
    isMultipleRef?.current.resetSelectedValues();
  };
  const validateField = (field: AddQuestionFieldProjectHead) => {
    setErrors(
      validateAddQuestionsFormProjectHead({
        formValues,
        errors,
        field,
      })
    );
  };

  const handleSubmit = () => {
    const validationError = validateAddQuestionsFormProjectHead({
      formValues,
      errors,
    });
    if (hasFormError(validationError)) {
      setErrors(validationError);
    } else {
      dispatch(
        createQuestion({
          isMultiOption: formValues?.IsMultiOption,
          question: [
            ...values,
            {
              options: [
                ...value2,
                { option: (Option === "" && "null") || Option },
              ],
              ...formValues,
              ...selectedStream,
              ...selectedTest,
            },
          ],
        })
      );
      alert("Added Succesfully");
      history.push(ROUTES.STREAM_TABLE);
    }
  };

  useEffect(() => {
    dispatch(handleGetCategory());
    dispatch(handleStreamMaster());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (formValues.IsMultiOption === "Others") {
      setFormValues({ ...formValues, answer: "null" });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.IsMultiOption]);

  const canSave = !!Option;

  return (
    <PageWrapper>
      <ContainerWrapper>
        <FlexWrapper hasBorder>
          <BackButton handleBack={() => history.goBack()} />
          <SectionTitle title={selectedTest?.testName} />
        </FlexWrapper>
        <TITLE>{"Add Question"}</TITLE>
        <>
          <Form>
            <FlexWrapper>
              {values.map((element, index) => (
                <>
                  <AddQuestionWrapper key={index}>
                    <InputWrapper>
                      <Input
                        inputType="text"
                        isDisabled={true}
                        value={element.IsMultiOption}
                        width="60"
                      />
                    </InputWrapper>
                    <InputWrapper>
                      <Input
                        inputType="text"
                        isDisabled={true}
                        value={element.question}
                        width="60"
                      />
                    </InputWrapper>
                    {element.answer !== "" && (
                      <InputWrapper>
                        <Input
                          inputType="text"
                          isDisabled={true}
                          value={element.answer}
                        />
                      </InputWrapper>
                    )}
                    <InputWrapper>
                      <Input
                        inputType="text"
                        isDisabled={true}
                        value={element.categoryName}
                      />
                    </InputWrapper>

                    {(element.options.length > 1 &&
                      element.options.map((data, i) => (
                        <InputWrapper key={i}>
                          <Input
                            inputType="text"
                            isDisabled={true}
                            value={data.option}
                          />
                        </InputWrapper>
                      ))) ||
                      null}
                    <PrimaryButton
                      style={{
                        marginTop: "8px",
                      }}
                      onClick={() => handleRemove(index)}
                    >
                      Clear
                    </PrimaryButton>
                  </AddQuestionWrapper>
                </>
              ))}
              <>
                <QuestionWrapper>
                  <InputWrapper width="50%">
                    <Dropdown
                      singleSelect
                      ref={catgoryRef}
                      error={errors.categoryName}
                      dropdownList={CategoryDropdown}
                      placeholder={"Category.."}
                      handleSelect={([value]: Array<DropdownListProps>) => {
                        setFormValues({
                          ...formValues,
                          categoryName: value.name,
                          categoryID: value.id,
                        });
                        setErrors({ ...errors, categoryName: "" });
                      }}
                    />
                  </InputWrapper>
                  <InputWrapper width="40%">
                    <Dropdown
                      isRequired
                      singleSelect
                      ref={isMultipleRef}
                      dropdownList={IsMultipleDP}
                      placeholder={"Select multiple choice.."}
                      handleSelect={([value]: Array<DropdownListProps>) => {
                        setFormValues({
                          ...formValues,
                          IsMultiOption: value.name,
                        });
                      }}
                    />
                  </InputWrapper>
                  <InputWrapper width="50%">
                    <Input
                      placeholder={"Add Question.."}
                      inputType="textarea"
                      value={formValues.question || ""}
                      name="Title"
                      onBlur={() => validateField("question")}
                      error={errors.question}
                      onChange={(value: string) => {
                        setFormValues({ ...formValues, question: value });
                      }}
                    />
                  </InputWrapper>

                  {formValues?.IsMultiOption === "False" && (
                    <InputWrapper width="40%">
                      <Dropdown
                        ref={answerRef}
                        singleSelect
                        dropdownList={trueORfalse}
                        error={errors.answer}
                        placeholder={"Select ans.."}
                        handleSelect={([value]: Array<DropdownListProps>) => {
                          setFormValues({ ...formValues, answer: value.name });
                          setErrors({ ...errors, answer: "" });
                        }}
                      />
                    </InputWrapper>
                  )}

                  {formValues?.IsMultiOption === "True" && (
                    <InputWrapper width="40%">
                      <Input
                        placeholder={"Answer"}
                        inputType="text"
                        value={formValues.answer || ""}
                        name="Title"
                        onBlur={() => {
                          const error = validateRequired(formValues?.answer);
                          setAnswerError(error);
                        }}
                        error={answerError}
                        onChange={(value: string) => {
                          setFormValues({ ...formValues, answer: value });
                        }}
                      />
                    </InputWrapper>
                  )}
                </QuestionWrapper>
                <OptionWrapper>
                  {formValues?.IsMultiOption === "True" && (
                    <div>Add Options:</div>
                  )}
                  {value2.map((element, i) => (
                    <WrapperValue key={i} style={{padding:'4px'}}>
                      <Input
                        inputType="text"
                        isDisabled={true} 
                        height="45px"  
                        value={element.option}
                      />
                      <PrimaryButton
                        style={{
                          marginTop: "1px",
                        }}
                        onClick={() => handleOptionRemove(i)}
                      >
                        <b>-</b>
                      </PrimaryButton>
                    </WrapperValue>
                  ))}
                  {(formValues?.IsMultiOption === "True" && (
                    <>
                      <DropdownWrapper style={{padding:'10px'}}>
                        <Input
                          placeholder={"Add Options"}
                          inputType="text"
                          value={Option || ""} 
                          onBlur={() => {
                            const errors = validateRequired(Option);
                            setoptionErrors(errors);
                          }}
                          error={optionErrors}
                          name="Title"
                          onChange={(value: string) => {
                            setOption(value);
                          }}
                        />
                        <PrimaryButton
                          type="button"
                          disabled={!canSave}
                          onClick={addOptions}
                          style={{
                            marginTop: "1px",
                          }}
                        >
                          <b>+</b>
                        </PrimaryButton>
                      </DropdownWrapper>
                    </>
                  )) ||
                    null}
                </OptionWrapper>
              </>
            </FlexWrapper>
          </Form>
          <FlexWrapper justifyContent="center">
            <PrimaryButton onClick={handleSubmit}>Submit</PrimaryButton>
            <SecondaryButton type="button" onClick={addFormfields}>
              Add More
            </SecondaryButton>
          </FlexWrapper>
        </>
      </ContainerWrapper>
    </PageWrapper>
  );
};

export default CreateProductHeadQuestion;
