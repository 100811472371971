import { createAsyncThunk } from "@reduxjs/toolkit";
import apiEndpoints from "../../const/apiEndpoints";
import history from "../../const/history";
import ROUTES from "../../const/routes";
import api from "../../services";
import { addCategory, AddTools, categoryList, DeleteCategoryPayload, getCatergorybySteram, GetTools } from "./types";

export const handleGetCategory = createAsyncThunk(
  "categories",
  async (): Promise<Array<categoryList>> => {
    const response = await api.get(apiEndpoints.getCategory);
    return response.data.message;
  }
);

export const addCategoryInput = createAsyncThunk(
  "categories/addCategory",
  async (requestPayload: addCategory, { dispatch }): Promise<addCategory> => {
    const response = await api.post(
      `${apiEndpoints.addCategory}`,
      requestPayload
    );

    if (response) {
      dispatch(handleGetCategory());
    }
    return response.data;
  }
);

export const getTools = createAsyncThunk(
  "tools/getTools",
  async (): Promise<Array<GetTools>> => {
    const response = await api.get(apiEndpoints.getTools);
    return response.data.message;
  }
);

export const addTools = createAsyncThunk(
  'tools/addTools',
  async (requestPayload: AddTools, { dispatch }): Promise<AddTools> => {
    const response = await api.post(
      `${apiEndpoints.addtools}`,
      requestPayload
    )
    if (response) {
      dispatch(getTools());
    }
    return response.data
  }
)

export const deleteAddCategory = createAsyncThunk(
  "addCategory/deleteaddCategory",
  async (requestPayload: DeleteCategoryPayload, { dispatch }): Promise<categoryList> => {
    const response = await api.post(`${apiEndpoints.deleteCategory}`, requestPayload);
    if (response) {
      dispatch(handleGetCategory())
    }
    return response?.data.message
  }
)

export const EditTools = createAsyncThunk(
  'tools/Edittools',
  async (requestPayload: GetTools, { dispatch }): Promise<GetTools> => {
    const response = await api.post(`${apiEndpoints.editTools}`, requestPayload)
    if (response?.data) {
      history.push(ROUTES.TOOLS)
      dispatch(getTools())
    }
    return response?.data
  }
)

export const EditCategory = createAsyncThunk(
  'tools/Edittools',
  async (requestPayload: categoryList, { dispatch }): Promise<categoryList> => {
    const response = await api.post(`${apiEndpoints.editCategory}`, requestPayload)
    if (response?.data) {
      history.push(ROUTES.ADD_CATEGORY)
      dispatch(handleGetCategory())
    }
    return response?.data
  }
)

export const getCourse = createAsyncThunk(
  'course/getCourse',
  async (): Promise<any> => {
    const response = await api.get(apiEndpoints.getCourse)
    return response?.data.message
  }
)

export const getCourseByStreamId = createAsyncThunk(
  'course/getCourseid',
  async (requestPayload: any): Promise<Array<getCatergorybySteram>> => {
    const response = await api.post(`${apiEndpoints.getCourseByStreamId}`, requestPayload)
    return response?.data.message
  }
)