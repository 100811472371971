import { ReactElement, useEffect } from "react";
import {
  CardWrapper,
  FlexWrapper,
  SectionTitle,
  PageWrapper,
  ContainerWrapper
} from "../../components";
import { CardData, CardTitle, HighChartUI } from "./subcomponents";
import { RootState } from "../../redux/store";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getResultCount, getUsers } from "../../redux/userlist/action";
import * as Highcharts from 'highcharts'
import useBreakpoint from "use-breakpoint";
import BREAKPOINTS from "../../const/breakpoint";
import { getResultByMonth, getUserByMonth } from "../../redux/userlist/api";

const Dashboard = (): ReactElement => {

  const dispatch = useDispatch();
  const { breakpoint } = useBreakpoint(BREAKPOINTS)
  const isMobile = breakpoint === 'mobile'
  const isDesktop = breakpoint === 'desktop'

  const { UserCount, ResultCount, getDashboardResultCount, getDashboardUserCount } = useSelector(
    (state: RootState) => ({
      UserCount: state.userDetailsList.users,
      ResultCount: state.userDetailsList.count.count,
      getDashboardResultCount: state.userDetailsList.getDashboardResultCount?.message,
      getDashboardUserCount: state.userDetailsList.getDashboardUserCount?.message
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(getUsers());
    dispatch(getResultCount());
    dispatch(getResultByMonth());
    dispatch(getUserByMonth());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const determineUser = () => {
    return {
      chart: {
        type: 'areaspline', 
      },
      title: {
        text: ' '
      },
      legend: {
        align: 'right',
        x: -30,
        verticalAlign: 'top',
        y: -10,
        floating: true,
        backgroundColor: 'white',
        borderColor: 'orange',
        borderWidth: 1,
        shadow: false
      },
      colors: ['#3D57AA', '#f19c4c'],
      xAxis: {
        categories: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
        title: {
        },
      },
      yAxis: {
        labels: {
          format: "{value}%"
        },
        title: {
          text: ''
        }
      },
      credits: {
        enabled: false
      },
      plotOptions: {
        areaspline: {
          marker: {
            enabled: false,
            symbol: 'circle',
            radius: 2,
            states: {
              hover: {
                enabled: true
              }
            }
          },
        }
      },
      series: [{
        name: 'New User',
        data: getDashboardUserCount
      },
      {
        name: 'Test Taken',
        data: getDashboardResultCount
      }],
    };
  }
  return (
    <PageWrapper>
      <ContainerWrapper>
        <FlexWrapper>
          <SectionTitle title="Dashboard" />
        </FlexWrapper>
        <FlexWrapper>
          <CardWrapper width="25" borderBottom="4px solid #F47F34">
            <CardTitle>Total Registration</CardTitle>
            <CardData>{UserCount.length}</CardData>
          </CardWrapper>
          <CardWrapper width="25" borderBottom="4px solid #F47F34">
            <CardTitle>Test Completed</CardTitle>
            <CardData>{ResultCount}</CardData>
          </CardWrapper>
        </FlexWrapper>
        <HighChartUI
          containerProps={{
            style: { width: isMobile || isDesktop ? '100%' : '90%' }
          }}
          highcharts={Highcharts}
          options={determineUser()}
        />
      </ContainerWrapper>
    </PageWrapper>
  );
};

export default Dashboard