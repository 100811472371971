import { createAsyncThunk } from "@reduxjs/toolkit";
import apiEndpoints from "../../const/apiEndpoints";
import api from "../../services";
import { AddStreamMaster, DeleteStreamPayload, GetStreamResponse, StreamList } from "./types";

export const handleStreamMaster = createAsyncThunk(
  "streamMaster",
  async (): Promise<GetStreamResponse> => {
    const response = await api.get(apiEndpoints.getStream);
    return response.data.message;
  }
);

export const addStreamMasterInput = createAsyncThunk(
  "streammaster/addStreamMaster",
  async (requestPayload: AddStreamMaster, { dispatch }): Promise<AddStreamMaster> => {
    const response = await api.post(apiEndpoints.addStream, requestPayload);
    if (response) {
      dispatch(handleStreamMaster())
    }
    return response.data;
  }
);

export const deleteStream = createAsyncThunk(
  "user/deleteUser",
  async (requestPayload: DeleteStreamPayload, {dispatch}): Promise<StreamList> => {
    const response = await api.post(`${apiEndpoints.deleteStream}`, requestPayload);
   if(response) {
     dispatch(handleStreamMaster())
   }
   return response?.data.message
  }
)
