import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { addAssignCategoryInput, handleGetAssignCategory } from "./api";
import { InitialState, assignCategoryList } from "./types";

const initialState: InitialState = {
  isLoading: false,
  AssignCategoryStream: [],
  AssignCategoryList: null, 
  selectedAssignDetails: null,
  selectcategoryStream: {
    streamID: "",
    streamName: "",
    categoryList: [{
      categoryID: '',
      categoryName: ''
    }]
  }
};
export const assigncategorySlice = createSlice({
  name: "assignCategories",
  initialState,
  reducers: {
    updateHasError: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setAssignCategorySelected: (state, action: PayloadAction<assignCategoryList | null>) => {
      state.selectedAssignDetails = action?.payload
    }
  },
  extraReducers: {
    [handleGetAssignCategory.pending.toString()]: (state) => {
      state.isLoading = false;
    },
    [handleGetAssignCategory.fulfilled.toString()]: (
      state,
      action: PayloadAction<Array<assignCategoryList>>
    ) => {
      state.isLoading = false;
      state.AssignCategoryStream = action?.payload;
    },
    [handleGetAssignCategory.rejected.toString()]: (state) => {
      state.isLoading = false;
    },
    [addAssignCategoryInput.pending.toString()]: (state) => {
      state.isLoading = true;
    },
    [addAssignCategoryInput.fulfilled.toString()]: (state) => {
      state.isLoading = true;
    },
    [addAssignCategoryInput.rejected.toString()]: (state) => {
      state.isLoading = false;
    },
  },
});

export default assigncategorySlice.reducer;
