export interface AuthenticatePayload {
  loginDevice: 'mobile' | 'website'
}

export interface UserInfo {
  message: string;
  name: string;
  success: string;
  token: string;
}

interface UserState {
  isLoggedIn: boolean;
  isLoading: boolean;
  email: string;
  password: string;
  hasError: boolean;
  userInfo: UserInfo | null;
}

export const initialState: UserState = {
  isLoggedIn: false,
  isLoading: false,
  email: "",
  password: "",
  hasError: false,
  userInfo: null,
};