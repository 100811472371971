import styled from "styled-components";
import { Button } from "react-bootstrap";

const ActionButton = styled(Button)`
  margin: ${({ margin }) => (margin ? `${margin}%` : "auto")};
  margin-top: ${({ marginTop }) => (marginTop ? `${marginTop}px` : "auto")};
  margin-left: ${({ marginLeft }) => (marginLeft ? `${marginLeft}px` : "auto")};
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? `${backgroundColor}` : "#f47f34"};
  border: none;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  &:hover {
    box-shadow: 1px 2px 10px #f47f34;
    background-color: #f47f34;
  }

  &:focus {
    background-color: #f47f34;
  }
  &:active {
    background-color: #f47f34;
    box-shadow: 1px 2px 10px #f47f34;
  }
`;

export default ActionButton;
