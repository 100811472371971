import { ReactElement } from "react"
import { shallowEqual, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { BackButton, Column, FlexWrapper, PageWrapper } from "../../../../components"
import strings from "../../../../locale/en"
import { assignCategoryList } from "../../../../redux/AssignCategory/types"
import { RootState } from "../../../../redux/store"
import { CardWrapper } from "../subcomponents"

const AssignCategoryDetails = (): ReactElement => {
    const { assignList } = useSelector(
        (state: RootState) => ({
            assignList: state.AssignReducer.selectedAssignDetails as assignCategoryList
        }),
        shallowEqual
    );
    const {
        details: {
            assignCategory: {
                name
            }
        }
    } = strings
    const history = useHistory()

    return (
        <PageWrapper id="container">
            <div>
                <BackButton
                    handleBack={() => {
                        history.goBack()
                    }}
                />
            </div>
            <FlexWrapper justifyContent="space-around">
                <CardWrapper>
                    {assignList.Category.map((categoryinfo) => (
                        <Column keyName={name} value={categoryinfo?.category_name} /> 
                    ))}
                </CardWrapper>
            </FlexWrapper>
        </PageWrapper>
    )
}

export default AssignCategoryDetails
