import { ReactElement } from "react";
import { Button, Toast } from "react-bootstrap";
import styled from "styled-components";
import { Modal } from "../../../components";
import { PrimaryButton } from "../../../components/Button";
import { colors } from "../../../const/theme";
import { ToolsActionProps } from "./types";

const UserlistWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const Icon = styled(Button)`
  border: none;
  color: rgb(242, 127, 54);
  background: none;
  text-transform: capitalize;
  &:hover,
  &:focus {
    border-color: none;
    background: none;
    color: ${colors.black};
  }
  max-width: 40px;
`;

export const ToolsListAction = ({
  handleEdit,
}: ToolsActionProps): ReactElement => {
  return (
    <UserlistWrapper>
      <PrimaryButton onClick={handleEdit}>Edit</PrimaryButton>
    </UserlistWrapper>
  );
};

export const BootStrapModal = styled(Modal)``;

export const ErrorWrapper = styled.div` 
background-color: red; 
height: 35px;
display: flex;
justify-content: center;
align-items: center;
color: white;
font-family: "Montserrat", sans-serif; 
margin: 0 auto;
`
export const ToastMessage = styled(Toast)`  
margin: 0 auto;
position: absolute;
z-index: 999;
left:34rem;
top: 110px;
`