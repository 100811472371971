import { ReactElement } from 'react'
import { Modal as BootstrapModal } from 'react-bootstrap'
import { colors } from '../../const/theme'
import { Body, H3 } from '../../typography' 
import ModalProps from './typings'
import { PrimaryButton, SecondaryButton } from '../../components/Button/index'

const DeleteModal = ({
  title,
  description,
  isLargeModal,
  handleCancel,
  handleSubmit,
  children,
  submitButtonText,
  cancelButtonText
}: ModalProps): ReactElement => {
  return (
    <BootstrapModal
      show={true}
      size={isLargeModal ? 'lg' : 'sm'}
      onHide={handleCancel}
      backdrop="static"
      keyboard={false}
    >
      <BootstrapModal.Header closeButton>
        {title && <H3 color={colors.purple}>{title}</H3>}
      </BootstrapModal.Header>
      {description && (
        <BootstrapModal.Body>
          <Body>{description}</Body>
        </BootstrapModal.Body>
      )}
      {children && children}
      <BootstrapModal.Footer>
        <SecondaryButton variant="outline-secondary" onClick={handleCancel}>
          {'Cancel'}
        </SecondaryButton>
        <PrimaryButton onClick={handleSubmit}>
          {'Submit'}
        </PrimaryButton>
      </BootstrapModal.Footer>
    </BootstrapModal>
  )
}

export default DeleteModal
