import { createAsyncThunk } from "@reduxjs/toolkit";
import apiEndpoints from "../../const/apiEndpoints";
import history from "../../const/history";
import ROUTES from "../../const/routes";
import api from "../../services";
import {
  DeleteUserPayload,
  GetUserResponse,
  ResultCount,
  resultResponse,
  SelectStream,
  UserList,
} from "./types";

export const getUsers = createAsyncThunk(
  "user/getUser",
  async (): Promise<GetUserResponse> => {
    const response = await api.get(apiEndpoints.getUser);
    return response.data.message;
  }
);

export const selectStreamInput = createAsyncThunk(
  "streammaster/addStreamMaster",
  async (requestPayload: SelectStream): Promise<SelectStream> => {
    const response = await api.post(apiEndpoints.getUserById, requestPayload);
    return response.data;
  }
);

export const deleteUser = createAsyncThunk(
  "user/deleteUser",
  async (
    requestPayload: DeleteUserPayload,
    { dispatch }
  ): Promise<UserList> => {
    const response = await api.post(
      `${apiEndpoints.deleteUser}`,
      requestPayload
    );
    if (response) {
      dispatch(getUsers());
    }
    return response?.data.message;
  }
);

export const editUser = createAsyncThunk(
  "user/EditUser",
  async (requestPayload: UserList, { dispatch }): Promise<UserList> => {
    const response = await api.post(`${apiEndpoints.editUser}`, requestPayload);
    if (response?.data) {
      dispatch(getUsers());
      history.push(ROUTES.USER);
    }
    return response?.data;
  }
);

export const getResultCount = createAsyncThunk(
  "user/Get_result_count",
  async (): Promise<ResultCount> => {
    const response = await api.get(apiEndpoints.getResultCount);
    return response.data;
  }
);

export const getResult = createAsyncThunk(
  "user/getResult",
  async (): Promise<Array<resultResponse>> => {
    const response = await api.get(apiEndpoints.getResult);
    return response.data.message;
  }
);

export const getResultByMonth = createAsyncThunk(
  'result/getResultCount',
  async (): Promise<any> => {
    const response = await api.get(apiEndpoints.getResultByMonth)
    return response?.data
  }
)

export const getUserByMonth = createAsyncThunk(
  'user/getUsreCount',
  async (): Promise<any> => {
    const response = await api.get(apiEndpoints.getUserByMonth)
    return response?.data
  }
)