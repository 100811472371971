import styled from "styled-components";
import HighchartsReact from "highcharts-react-official";

export const HighChartUI = styled(HighchartsReact)`
  font-family: "Montserrat", sans-serif; 
`

export const CardTitle = styled.p`
  font-size: 14px;
  font-weight: 500;
  color: #141A45;
  font-family: "Montserrat", sans-serif;
  margin: 2px;
  padding-top: 16px;
  @media (max-width: 1000px) {
    font-size: 9px;
  }
`;

export const CardData = styled.p`
  font-size: 28px;
  font-weight: 600;
  color: #3335CF;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 4px;
  padding-left: 4px;
  margin-bottom: 4px;
  @media (max-width: 600px) {
    font-size: 20px;
  }
`;
