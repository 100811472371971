import { createAsyncThunk } from "@reduxjs/toolkit"
import apiEndpoints from "../../const/apiEndpoints"
import { RootState } from "../store"
import { UserInfo } from "./types"
import api from "../../services"

export const handleAuthenticate = createAsyncThunk(
  'user/login',
  async (_undefined,
    { getState }
  ): Promise<UserInfo> => {
    const {
      user: { email, password },
    } = getState() as RootState;
    const data = {
      email: email,
      password: password,
    };
    const response = await api.post(apiEndpoints.login, data)
    const userInfo = response?.data;
    sessionStorage.setItem('Authorization', userInfo?.token)
    return userInfo
  }
)