import { validateRequired } from "../../../../helpers/formValidation";
import { ValidateAddQuestionFormProjectHead } from "./typings";

const validateAddQuestionsFormProjectHead = ({
  formValues,
  errors,
  field,
}: ValidateAddQuestionFormProjectHead): Record<string, string> => {
  let formErrors: Record<string, string> = { ...errors };
  const { question, categoryName } = formValues;

  switch (field) {
    case "question":
      formErrors["question"] = validateRequired(question);
      break;
    case "categoryName":
      formErrors["categoryName"] = validateRequired(categoryName);
      break;
    default:
      formErrors["question"] = validateRequired(question);
      formErrors["categoryName"] = validateRequired(categoryName);
  }

  return formErrors;
};

export default validateAddQuestionsFormProjectHead;
