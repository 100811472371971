import { addcategorySlice } from "./reducer";
import { handleGetCategory, addCategoryInput, getTools, getCourse } from "./api";

const {
    updateHasError,
    resetAddCategory,
    updateSelectedCategory,
    updateSelectedTools,
    updateSelectStream
} = addcategorySlice.actions;

export {
    updateHasError,
    handleGetCategory,
    addCategoryInput,
    resetAddCategory,
    getTools,
    updateSelectedCategory,
    updateSelectedTools,
    getCourse, updateSelectStream
};