import { ReactElement, SyntheticEvent, useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { UserFilterSection, UserlistAction } from "./subcomponents";
import { resetInputValues, tableHeader } from "./const";
import {
  TableHeader,
  TableRow,
  TableWrapper,
  PageWrapper,
  SectionTitle,
  FlexWrapper,
  Loader,
  Input,
  Pagination,
  ContainerWrapper,
  CountTitle,
} from "../../../components";
import strings from "../../../locale/en";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  addCategoryInput,
  deleteAddCategory,
  getCourse,
  getTools,
  handleGetCategory,
} from "../../../redux/AddCategory/api";
import { RootState } from "../../../redux/store";
import { addCategory } from "../../../redux/AddCategory/types";
import { resetAddCategory } from "../../../redux/AddCategory/action";
import { getCourseDropdown, getToolsDropdown } from "../../../helpers/dropdown";
import { useRef } from "react";
import { ErrorWrapper, ToastMessage } from "../Tools/subcomponent";

const AddCategory = (): ReactElement => {
  const { categorydata, addcategory, isLoading, toolDate, courseData, message } =
    useSelector(
      (state: RootState) => ({
        categorydata: state.categorylist.categories,
        addcategory: state.categorylist.selectcategoryMaster as addCategory,
        isLoading: state.categorylist.isLoading,
        toolDate: state.categorylist.toolsList,
        courseData: state.categorylist.getCourseArray,
        message: state.categorylist.addCat.message
      }),
      shallowEqual
    );

  const dispatch = useDispatch();
  const toolsRef = useRef(null);
  const courseRef = useRef(null);

  const {
    user: {
      category: { addCategort },
    },
  } = strings;

  const [values, setValues] = useState(addcategory);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [resetValues, setResetValues] = useState(resetInputValues);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [dataPerPage, setDataPerPage] = useState(10);
  const [isSelectAll, setIsSelectAll] = useState(false)
  const [showtoast, setshowtoast] = useState(false)
  //Get current data
  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = categorydata.slice(indexOfFirstData, indexOfLastData);

  const ToolsDropDown = toolDate ? getToolsDropdown(toolDate) : [];
  const CourseDropdown = courseData ? getCourseDropdown(courseData) : [];

  useEffect(() => {
    dispatch(getTools());
    dispatch(handleGetCategory());
    dispatch(getCourse());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    dispatch(addCategoryInput({ ...values, is_special: isSelectAll }));
  };

  const clearValues = () => {
    setResetValues({
      category: true,
      description: true,
    });
    setValues(addcategory);
  };

  const resetDropdownValues = () => {
    toolsRef?.current?.resetSelectedValues();
    courseRef?.current?.resetSelectedValues();
  };

  //ReadMore
  const [read, setRead] = useState(true)
  // const res = read ? categorydata.map((x) => x?.description.slice(0,20)) : categorydata.map((x) => x?.description)
  const isToogle = () => {
    setRead(!read)
  }
  useEffect(() => {
    if (message === 'Already a special category is active') {
      setshowtoast(true)
    } else if (message === 'Category Added') {
      setshowtoast(false)
    }
  }, [message])

  return (
    <PageWrapper>
      <ContainerWrapper>
        <CountTitle title="Total Category" count={categorydata.length} />
        <FlexWrapper justifyContent="space-between" noPadding>
          <SectionTitle title={addCategort} />
        </FlexWrapper>
        {showtoast && (
          <ToastMessage
            show={true}
            bg="danger"
            autohide
            delay={1000}
            onClose={() => setshowtoast(false)}
          >
            <ErrorWrapper>Already a special category is active!</ErrorWrapper>
          </ToastMessage>
        )}
        <UserFilterSection
          formCheck={isSelectAll}
          handlecheckboxClcik={() => {
            setIsSelectAll(!isSelectAll)
          }}
          handleAddChange={(value: string) => {
            setValues({ ...values, category: value });
          }}
          addvalue={values?.category || ""}
          handleDescriptionChange={(value: string) => {
            setValues({ ...values, description: value });
          }}
          descriptionValue={values?.description || ""}
          handleToolsSelect={(value) => {
            setValues({
              ...values,
              toolList: {
                tools: value,
              },
            });
          }}
          ToolsList={ToolsDropDown}
          handleAdd={(e: SyntheticEvent) => {
            dispatch(resetAddCategory());
            handleSubmit(e);
            clearValues();
            resetDropdownValues();
          }}
          courseList={CourseDropdown}
          handlecourseSelect={(value) => {
            setValues({
              ...values,
              courseName: {
                course: value,
              },
            });
          }}
          resetRef={toolsRef}
          courseRef={courseRef}
        />
        <FlexWrapper justifyContent="end" noPadding>
          <Input
            placeholder="Search"
            width="25%"
            value={search}
            onChange={(value: string) => {
              setSearch(value);
            }}
          />
        </FlexWrapper>
        {isLoading ? (
          <Loader />
        ) : (
          <div style={{ marginTop: "50px" }}>
            <TableWrapper>
              <Table
                size="sm"
                responsive="sm"
                borderless
                className="table table-striped"
              >
                <TableHeader>
                  <TableRow>
                    {tableHeader?.map((header, index) => (
                      <th key={`complete-session-header-${index}`}>{header}</th>
                    ))}
                  </TableRow>
                </TableHeader>
                <tbody>
                  {currentData
                    // eslint-disable-next-line array-callback-return
                    .filter((categoryValues) => {
                      if (search === "") {
                        return categoryValues;
                      } else if (
                        categoryValues?.name
                          .toLowerCase()
                          .includes(search.toLowerCase()) ||
                        categoryValues?.description
                          .toLowerCase()
                          .includes(search.toLowerCase())
                      ) {
                        return categoryValues;
                      }
                    })
                    .map((categoryInfo, index) => {
                      const { name, description, id } = categoryInfo;
                      const res = read ? description.slice(0, 25) : description
                      return (
                        <TableRow key={`user-${index}`}>
                          <td>{index + 1 + indexOfFirstData}</td>
                          <td>{name}</td>
                          <td
                            onClick={isToogle}
                          >
                            {res}
                            {description.length > 25 &&(
                              <p style={{
                                color: 'red',
                                cursor: 'pointer',
                              }}>
                                {read ? '..Read More' : 'Read Less'}
                              </p>)
                              }

                          </td>
                          <td>
                            <UserlistAction
                              handleDelete={() => {
                                dispatch(
                                  deleteAddCategory({
                                    id: id,
                                  })
                                );
                              }}
                            />
                          </td>
                        </TableRow>
                      );
                    })}
                </tbody>
              </Table>
            </TableWrapper>
            <Pagination
              currentData={indexOfLastData}
              dataLimit={categorydata.length}
              currentPage={currentPage}
              dataPerPage={dataPerPage}
              handleCurrentPage={setCurrentPage}
            />
          </div>
        )}
      </ContainerWrapper>
    </PageWrapper>
  );
};

export default AddCategory;
