import { colors } from '../../const/theme'
import Button from 'react-bootstrap/esm/Button'
import styled from 'styled-components'

export const PrimaryButton = styled(Button)`  
  background-color: #1FA535; 
  color: ${colors.white};
  border: none;
  font-family: "Montserrat", sans-serif;
  &:hover,
  &:focus,
  &:hover,
  &:active,
  &:disabled{
    background: #1FA535;
    color: white;
    border-color: ${({ theme }) => theme.button.primary};
  }
  margin: auto 4px;
  cursor: pointer;
  font-size: 14px;
  @media (max-width:500px) {
    margin:  0 auto ;
    background-color: red;
  }
`

export const SecondaryButton = styled(Button)`
  background: ${colors.white} !important; 
  font-weight:550;
  font-family: "Montserrat", sans-serif;
  background-color: ${colors.white};
  color: ${({ theme }) => theme.button.primary};
  border-color: ${({ theme }) => theme.button.primary};
  &:hover,
  &:active,
  &:focus {
    background: ${({ theme }) => theme.button.secondary} !important;
    color: ${({ theme }) => theme.button.primary};
    border-color: ${({ theme }) => theme.button.primary};
  }
  font-size: 12px;
  margin: auto 4px;
`
