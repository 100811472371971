export const trueORfalse = [
  {
    id: "01",
    name: "True",
  },
  {
    id: "02",
    name: "False",
  },
];

export const category = [
  {
    id: "01",
    name: "Web Developer",
  },
  {
    id: "02",
    name: "Full Stock Developer",
  },
];
