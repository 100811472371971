import styled from "styled-components";
import { weight } from "../../../../const/fonts";

export const TITLE = styled.p`
  font-size: 20px;
  font-weight: ${weight.normal};
  margin: 10px;
`;

export const InputWrapper = styled.div`
  margin-right: 10px;
  width: 60%;
  @media (max-width:500px) {
    width: 100%;
    padding: 10px;
  }
`;

export const DropdownWrapper = styled.div`
  width: 20%;
  margin-right: 10px;
  @media (max-width:500px) {
    width: 100%;
    padding: 10px;
  }
`;

export const AddQuestionWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 10px;
  justify-content: space-evenly;
  @media (max-width:500px) {
    display: flex;
    flex-direction: column;
  }
`;
