import Card from "react-bootstrap/Card";
import styled from "styled-components";

const CardWrapper = styled(Card)`
  box-shadow: 0 1px 10px 0 ${({ theme }) => theme.card.border};
  width: ${({ width }) => (width ? `${width}%` : "45%")};
  height: ${({ height }) => (height ? height : "auto")};
  padding-left: 16px;
  background-color: ${({ color }) => (color ? color : "")};
  margin: 12px;
  border-bottom: ${({ borderBottom }) =>
    borderBottom ? borderBottom : "none"};
  @media (max-width: 700px) {
    width: 35%;
    margin-bottom: 16px;
    height: 80px;
  }
`;

export default CardWrapper;