import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faMobile,
  faUser,
  faUserGroup,
  faSquarePollVertical,
  faClipboardList,
  faPeopleArrows,
  faLock,
  faPen,
  faMobileAlt,
  faBars,
  faCogs,
  faTrophy,
  faUserCog,
  faFolder,
  faChevronDown,
  faChevronRight,
  faChevronLeft,
  faTachometerAlt,
  faUserPlus,
  faInfoCircle,
  faDonate,
  faUserTag,
  faNewspaper,
  faUserCircle,
  faBell,
  faSearch,
  faMedal,
  faLineChart,
  faLayerGroup,
  faTimesCircle,
  faUserCheck,
  faPowerOff
} from "@fortawesome/free-solid-svg-icons";

import {
  faStickyNote,
  faTrashAlt,
  faEdit,
  faEyeSlash,
  faEye,
  faFileAlt,
} from "@fortawesome/free-regular-svg-icons";

library.add(
  faMobile,
  faUser,
  faUserGroup,
  faSquarePollVertical,
  faClipboardList,
  faLayerGroup,
  faPeopleArrows,
  faLock,
  faMobileAlt,
  faBars,
  faCogs,
  faTrophy,
  faStickyNote,
  faUserCog,
  faFolder,
  faChevronDown,
  faChevronRight,
  faTachometerAlt,
  faUserPlus,
  faInfoCircle,
  faTrashAlt,
  faEdit,
  faEyeSlash,
  faLineChart,
  faPen,
  faEye,
  faDonate,
  faUserTag,
  faNewspaper,
  faChevronLeft,
  faUserCircle,
  faFileAlt,
  faBell,
  faSearch,
  faMedal,
  faTimesCircle,
  faUserCheck,
  faPowerOff, 
);
