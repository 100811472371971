import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  createQuestion,
  CreateTestApi,
  getAssignCategoryById,
  getLevels,
  getQuestion,
  GetQuestionApi,
  getTest,
} from "./api";
import {
  CreatetestByStream,
  GetAssignCategoryByIdResponse,
  GetLevels,
  getQuestionResponse,
  GetTest,
  InitialState,
  Test,
} from "./types";

const initialState: InitialState = {
  isLoading: false,
  getTestList: [],
  addQuestion: {
    question: "",
  },
  selectedStream: {
    streamID: "",
    streamName: "",
  },
  selectedTest: {
    test_ID: "",
    testName: "",
  },
  selectCreateTest: {
    testLevel: "",
    testName: "",
    streamID: "",
    streamName: "",
    testLevelID: "",
  },
  GetTestById: "",
  getLevelList: [],
  selectedQuestion: {
    isMultiOption: '',
    question: [
      {
        question: "",
        answer: "",
        test_ID: "",
        testName: "",
        categoryName: "",
        categoryID: "",
        streamID: "",
        streamName: "",
      },
    ],
  },
  GetAssignCategoryByIdResponseMaster: [],
  ProductHeadQuestion: {
    isMultiOption: '',
    question: [
      {
        question: "",
        options: [
          {
            option: "",
          },
        ],
        answer: "",
        test_ID: "",
        testName: "",
        IsMultiOption: "",
        categoryName: "",
        categoryID: "",
        streamID: "",
        streamName: "",
      },
    ],
  },
  updateQuestin: [],
  selectQuestionEdit: undefined
};
export const questionaryManagementSlice = createSlice({
  name: "questionaryManagement",
  initialState,
  reducers: {
    updateSelectedStream: (
      state,
      action: PayloadAction<CreatetestByStream>
    ) => {
      state.selectedStream = action?.payload;
    },
    updateSelectedTest: (state, action: PayloadAction<Test>) => {
      state.selectedTest = action?.payload;
    },
    updateSelectedQuestion: (state, action: PayloadAction<getQuestionResponse | null>) => {
      state.selectQuestionEdit = action?.payload
    }
  },
  extraReducers: {
    [getTest.pending.toString()]: (state) => {
      state.isLoading = true;
    },
    [getTest.fulfilled.toString()]: (
      state,
      action: PayloadAction<Array<GetTest>>
    ) => {
      state.isLoading = false;
      state.getTestList = action?.payload;
    },
    [getTest.rejected.toString()]: (state) => {
      state.isLoading = true;
    },
    [CreateTestApi.pending.toString()]: (state) => {
      state.isLoading = true;
    },
    [CreateTestApi.fulfilled.toString()]: (state) => {
      state.isLoading = false;
    },
    [CreateTestApi.rejected.toString()]: (state) => {
      state.isLoading = false;
    },
    [getLevels.pending.toString()]: (state) => {
      state.isLoading = true;
    },
    [getLevels.fulfilled.toString()]: (
      state,
      action: PayloadAction<Array<GetLevels>>
    ) => {
      state.isLoading = false;
      state.getLevelList = action?.payload;
    },
    [getLevels.rejected.toString()]: (state) => {
      state.isLoading = true;
    },
    [createQuestion.pending.toString()]: (state) => {
      state.isLoading = true;
    },
    [createQuestion.fulfilled.toString()]: (state) => {
      state.isLoading = false;
    },
    [createQuestion.rejected.toString()]: (state) => {
      state.isLoading = false;
    },
    [getQuestion.pending.toString()]: (state) => {
      state.isLoading = true;
    },
    [getQuestion.fulfilled.toString()]: (state) => {
      state.isLoading = false;
    },
    [getQuestion.rejected.toString()]: (state) => {
      state.isLoading = false;
    },
    [getAssignCategoryById.pending.toString()]: (state) => {
      state.isLoading = true;
    },
    [getAssignCategoryById.fulfilled.toString()]: (
      state,
      action: PayloadAction<Array<GetAssignCategoryByIdResponse>>
    ) => {
      state.isLoading = false;
      state.GetAssignCategoryByIdResponseMaster = action?.payload;
    },
    [getAssignCategoryById.rejected.toString()]: (state) => {
      state.isLoading = false;
    },
    [GetQuestionApi.fulfilled.toString()]: (
      state,
      action: PayloadAction<Array<getQuestionResponse>>
    ) => {
      state.isLoading = false;
      state.updateQuestin = action?.payload;
    },
  },
});

export default questionaryManagementSlice.reducer;
