import strings from '../locale/en'

export const hasFormError = (formErrors: Record<string, string>): boolean => {
  return !!Object.keys(formErrors).find((errorkey) => formErrors[errorkey])
}
const { validationMessages } = strings


export const validateRequired = (value: string): string => {
  const { required } = validationMessages.field

  if (!value) {
    return required
  } else {
    return ''
  }
}
