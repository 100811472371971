import { configureStore } from '@reduxjs/toolkit'
import menuReducer from './menu/reducer'
import userReducer from './user/reducer'
import userDetailsListReducer from './userlist/reducer'
import streamMasterReducer from "./streamMaster/reducer";
import AddCategoryReducer from "./AddCategory/reducer";
import AssignCategoryReducer from './AssignCategory/reducer'
import questionaryManagementReducer from './QuestionaryManagement/reducer'

const reducer = {
  menu: menuReducer,
  user: userReducer,
  userDetailsList: userDetailsListReducer,
  streamList: streamMasterReducer,
  categorylist: AddCategoryReducer,
  AssignReducer: AssignCategoryReducer,
  questionaryManagement: questionaryManagementReducer
};

const initialState = {}

export const store = configureStore({
  reducer: reducer,
  devTools: process.env.NODE_ENV !== 'production',
  preloadedState: initialState,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
