import { ReactElement } from "react";
import styled from "styled-components";
import { weight } from "../../../const/fonts";
import { Button, Form } from "react-bootstrap";
import { colors } from "../../../const/theme";
import { UserlistActionProps, UserFilterProps } from "./typings";
import {
  FlexWrapper,
  DropdownWrapper,
  Input,
  Dropdown,
} from "../../../components";
import { PrimaryButton } from "../../../components/Button";
import { DeleteButton } from "../../user/subcomponents";

export const TITLE = styled.p`
  font-size: 24px;
  font-weight: ${weight.bold};
  padding: 4%;
  padding-bottom: 2%;
`;

const UserlistWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const Icon = styled(Button)`
  border: none;
  color: rgb(242, 127, 54);
  background: none;
  text-transform: capitalize;
  &:hover,
  &:focus {
    border-color: none;
    background: none;
    color: ${colors.black};
  }
  max-width: 40px;
`;

export const InputDescriptionWrapper = styled.div`
  margin-left: 2%;
  width: 67%;
`;

export const FormCheckbox = styled(Form.Check)` 
input:checked{
  background-color: rgb(242, 127, 54);
  border: 1px solid rgb(242, 127, 54);
}
`
export const CheckboxWrapper = styled.div` 
display: flex;
flex-wrap: wrap;
justify-content: center;
width:140px;
text-align: center;
`
export const CheckLabel = styled.div` 
position: relative;
top: 8px;
font-family: "Montserrat", sans-serif; 
padding-left: 4px;
font-size: 15px;
font-weight: 500;
`

export const UserFilterSection = ({
  handleAddChange,
  handleAdd,
  addvalue,
  ToolsList,
  handleToolsSelect,
  descriptionValue,
  handleDescriptionChange,
  disableButton,
  handlecourseSelect,
  resetRef,
  courseList,
  courseRef,
  formCheck,
  handlecheckboxClcik
}: UserFilterProps): ReactElement => {
  return (
    <>
      <FlexWrapper>
        <CheckboxWrapper > 
            <FormCheckbox
              style={{
                marginTop: '7px'
              }}
              onClick={handlecheckboxClcik}
              checked={formCheck}

            />
            <CheckLabel>Is Special</CheckLabel> 
        </CheckboxWrapper>
        <DropdownWrapper>
          <Input
            placeholder={"Add Category"}
            value={addvalue}
            name="ReferenceId"
            height="50px"
            onBlur={() => { }}
            onChange={handleAddChange}
          />
        </DropdownWrapper>
        <DropdownWrapper width="100%">
          <Dropdown
            isRequired
            dropdownList={courseList}
            ref={courseRef}
            placeholder={"Select  Course"}
            handleSelect={handlecourseSelect}
          />
        </DropdownWrapper>
        <DropdownWrapper width="100%">
          <Dropdown
            isRequired
            dropdownList={ToolsList}
            ref={resetRef}
            placeholder={"Select  Tools"}
            handleSelect={handleToolsSelect}
          />
        </DropdownWrapper>
        <InputDescriptionWrapper>
          <Input
            inputType="textarea"
            placeholder={"Description"}
            onChange={handleDescriptionChange}
            value={descriptionValue}
          />
        </InputDescriptionWrapper>
      </FlexWrapper>
      <FlexWrapper justifyContent="center" noPadding>
        <PrimaryButton
          marginTop="4"
          marginLeft="16"
          disabled={disableButton}
          onClick={handleAdd}
        >
          Submit
        </PrimaryButton>
      </FlexWrapper>
    </>
  );
};

export const UserlistAction = ({
  handleDelete,
}: UserlistActionProps): ReactElement => {
  return (
    <UserlistWrapper>
      <DeleteButton onClick={handleDelete}>Delete</DeleteButton>
    </UserlistWrapper>
  );
};
