import { ReactElement, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  FlexWrapper,
  Input,
  PageWrapper,
  SectionTitle,
} from "../../../components";
import { PrimaryButton, SecondaryButton } from "../../../components/Button";
import { RootState } from "../../../redux/store";
import { editUser } from "../../../redux/userlist/api";
import { UserList } from "../../../redux/userlist/types";

const EditUser = (): ReactElement => {
  const { usersData } = useSelector((state: RootState) => ({
    usersData: state.userDetailsList.setSelectUser,
  }));
  const dispatch = useDispatch();
  const history = useHistory();
  const [values, setValues] = useState(usersData || ({} as UserList));

  const canSave =
    !!values?.user_name &&
    !!values?.email &&
    !!values?.last_name &&
    !!values?.country &&
    !!values?.mobile &&
    !!values?.profile;

  useEffect(() => {
    setValues(usersData)
  }, [usersData])

  return (
    <PageWrapper id="container">
      <SectionTitle title="Edit User" hasBorder />
      <FlexWrapper justifyContent="space-between" >
        <Input
          label="First Name"
          placeholder="FirstName"
          width="30%"
          isRequired
          value={values?.user_name || ""}
          onChange={(value: string) => {
            setValues({ ...values, user_name: value });
          }}
        />
        <Input
          label="Last Name"
          placeholder="Last Name"
          width="30%"
          isRequired
          value={values?.last_name || ""}
          onChange={(value: string) => {
            setValues({ ...values, last_name: value });
          }}
        />
        <Input
          label="Profile"
          placeholder="Profile"
          width="30%"
          isRequired
          value={values?.profile || ""}
          onChange={(value: string) => {
            setValues({ ...values, profile: value });
          }}
        />
        <Input
          label="E-Mail"
          placeholder="Email"
          width="30%"
          isRequired
          value={values?.email || ""}
          onChange={(value: string) => {
            setValues({ ...values, email: value });
          }}
        />
        <Input
          label="State"
          placeholder="State"
          width="30%"
          isRequired
          value={values?.state || ""}
          onChange={(value: string) => {
            setValues({ ...values, state: value });
          }}
        />
        <Input
          label="Country"
          placeholder="Country"
          width="30%"
          isRequired
          value={values?.country || ""}
          onChange={(value: string) => {
            setValues({ ...values, country: value });
          }}
        />
        <Input
          label="Mobile Number"
          placeholder="Mobile Number"
          width="30%"
          isRequired
          value={values?.mobile || ""}
          onChange={(value: string) => {
            setValues({ ...values, mobile: value });
          }}
        />
      </FlexWrapper>
      <FlexWrapper justifyContent="center">
        <SecondaryButton
          onClick={() => {
            history.goBack();
          }}
        >
          Back
        </SecondaryButton>
        <PrimaryButton
          disabled={!canSave}
          onClick={() => {
            dispatch(editUser(values));
          }}
        >
          Save
        </PrimaryButton>
      </FlexWrapper>
    </PageWrapper>
  );
};

export default EditUser;
