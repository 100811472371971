import styled from "styled-components";

const PageWrapper = styled.div`
 display: flex;
 background-color: #F5F6FA;
  flex-direction: column;
  width: 100%;
  padding: 24px;
  @media (max-width: 415px) {
    width: 100%;
  }
`;

export default PageWrapper;
