import axios from "axios";
import { BASEURL } from "../../services";

const ViewResult = (id?: string) => {
  const url = `${BASEURL}Download?id=${id}`;

  axios
    .get(url, {
      responseType: "blob",
    })
    .then((res) => {
      const file = new Blob([res.data], { type: "application/pdf" });
      //Build a URL from the file
      const fileURL = URL.createObjectURL(file);
      //Open the URL on new Window
      window.open(fileURL);
    })
    .catch((error) => {
      console.log(error);
    });
};

export default ViewResult;
