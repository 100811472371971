import { Button } from "react-bootstrap";
import styled from "styled-components";
import { weight } from "../../const/fonts";
import { ViewButton } from "./AddQuestion/createTest/subcomponents";
import { QuestionFilterProps } from "./typings";

export const PageWrapper = styled.div`
  width: 80%;
`;

export const ActionWrapper = styled.div`
  width: 12%;
  margin-top: 4%;
`;

export const TITLE = styled.p`
  font-size: 24px;
  font-weight: ${weight.bold};
  padding: 4%;
  padding-bottom: 2%;
`;

export const AddButton = styled(Button)` 
 margin: 0 25px;
 border: none;
 background-color: #f47f34;
 &:hover,
 &:active 
 &:focus{
    background-color: #f47f34;
  } 
`

export const QuestionCreateButton = ({
  handleGetById,
  disableButton
}: QuestionFilterProps) => {
  return <ViewButton onClick={handleGetById} disabled={disableButton}>View test</ViewButton>;
};

