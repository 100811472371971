import { ReactElement } from 'react'
import {
  LogoWrapper,
  MobileWrapper,
  HamburgerMenu,
  Logo
} from './subcomponents'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDispatch, useSelector } from 'react-redux'
import { updateIsMenuOpen } from '../../redux/menu/actions'
import { RootState } from '../../redux/store'
import logoImg from '../../asserts/logo.png'

const MobileMenu = (): ReactElement => {
  const { isMenuOpen } = useSelector((state: RootState) => state.menu)
  const dispatch = useDispatch()

  return (
    <MobileWrapper>
      <HamburgerMenu
        variant="light"
        onClick={() => dispatch(updateIsMenuOpen(!isMenuOpen))}
      >
        <FontAwesomeIcon
          icon={isMenuOpen ? ['fas', 'times-circle'] : ['fas', 'bars']}
          size="lg"
        />
      </HamburgerMenu>
      <LogoWrapper>
        <Logo src={logoImg} />
      </LogoWrapper>
    </MobileWrapper>
  )
}

export default MobileMenu
