const strings = {
  validationMessages: {
    field: {
      required: "Field is required",
    },
  },
  login: {
    login: "Login",
    message: "Login failed. Please check the credentials",
  },
  user: {
    title: "Users",
    enrollment: {
      enrolment: "ENROLLMENT",
    },
    dashboard: {
      title: "Dashboard",
    },
    streamMaster: {
      title: "Stream Master",
    },
    category: {
      title: "Category",
      addCategort: "Add Category",
      assignCategorystr: "Assign Category",
    },
    questionManagement: {
      title: "Question Management",
    },
    result: {
      title: "Result",
    },
  },
  details: {
    userDetails: {
      firstName: "First Name",
      lastName: "Last Name",
      email: "Email",
      profile: "Profile",
      id: "ID",
      city: "City",
      state: "State",
      country: "Country",
      employeeCode: "Employe Code",
      contactno: "Contact Number",
      userTypeId: "User Type",
      stream: "Stream",
    },
    streamDetails: {
      id: "ID",
      divisionid: "Division ID",
      name: "Stream Name",
      createdat: "Created Time",
      updatedat: "Updated Time",
      status: "Status",
    },
    assignCategory: {
      assignid: "Assign Category ID",
      name: "Category Name",
      Categoryid: "Category ID",
    },
  },
  tablefooter: {
    previous: "Previous",
    next: "Next",
  },
  button: {
    submit: "Submit",
  },
};
export default strings;
