/* eslint-disable array-callback-return */
import { ReactElement, useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  ContainerWrapper,
  FlexWrapper,
  Input,
  PageWrapper,
  Pagination,
  SectionTitle,
  TableHeader,
  TableRow,
  TableWrapper,
} from "../../components";
import ViewResult from "../../redux/result/api";
import { RootState } from "../../redux/store";
import { getResult } from "../../redux/userlist/api";
import { ViewButton } from "../Questioniar/AddQuestion/createTest/subcomponents";
import { tableHeader } from "./const";

const Result = (): ReactElement => {
  const { getResultList } = useSelector(
    (state: RootState) => ({
      getResultList: state.userDetailsList.getResultList,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const [searchTerm, setsearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [dataPerPage, setDataPerPage] = useState(10);

  //Get current data
  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = getResultList.slice(indexOfFirstData, indexOfLastData);

  useEffect(() => {
    dispatch(getResult());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageWrapper>
      <ContainerWrapper>
        <FlexWrapper justifyContent="space-between">
          <SectionTitle title="Results" />
          <Input
            value={searchTerm}
            width="25%"
            placeholder={"Search "}
            onChange={(value: string) => {
              setsearchTerm(value);
            }}
          />
        </FlexWrapper>
        <div style={{ marginTop: "50px" }}>
          <TableWrapper>
            <Table
              size="sm"
              responsive="sm"
              borderless
              className="table table-striped"
            >
              <TableHeader>
                <TableRow>
                  {tableHeader?.map((header, index) => (
                    <th key={`complete-session-header-${index}`}>{header}</th>
                  ))}
                </TableRow>
              </TableHeader>
              <tbody>
                {currentData
                  .filter((userFilter) => {
                    if (searchTerm === "") {
                      return userFilter;
                    } else if (
                      userFilter.mobile
                        ?.toLowerCase()
                        .includes(searchTerm.toLowerCase()) ||
                      userFilter.email
                        ?.toLowerCase()
                        .includes(searchTerm.toLowerCase()) ||
                      userFilter.user_name
                        ?.toLowerCase()
                        .includes(searchTerm.toLowerCase())
                    ) {
                      return userFilter;
                    }
                  })
                  .map((streamInfo, index) => {
                    return (
                      <TableRow key={`user-${index}`} height>
                        <td>{index + 1 + indexOfFirstData}</td>
                        <td>{streamInfo.user_name}</td>
                        <td>{streamInfo.email}</td>
                        <td>{streamInfo.mobile}</td>
                        <td>
                          <ViewButton
                            backgroundColor="#1FA535"
                            onClick={() => {
                              ViewResult(streamInfo.id);
                            }}
                          >
                            View result
                          </ViewButton>
                        </td>
                      </TableRow>
                    );
                  })}
              </tbody>
            </Table>
          </TableWrapper>
          <Pagination
            currentData={indexOfLastData}
            dataLimit={getResultList.length}
            currentPage={currentPage}
            dataPerPage={dataPerPage}
            handleCurrentPage={setCurrentPage}
          />
        </div>
      </ContainerWrapper>
    </PageWrapper>
  );
};

export default Result;
