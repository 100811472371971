/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { ReactElement, SyntheticEvent, useEffect, useMemo, useState } from "react";
import { Table } from "react-bootstrap";
import { UserFilterSection, UserlistAction } from "./subcomponents";
import { tableHeader } from "./const";
import {
  TableHeader,
  TableRow,
  TableWrapper,
  FlexWrapper,
  SectionTitle,
  PageWrapper,
  Loader,
  Input,
  Pagination,
  ContainerWrapper,
} from "../../components";
import strings from "../../locale/en";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import {
  deleteUser,
  getUsers,
  selectStreamInput,
} from "../../redux/userlist/action";
import { SelectStream } from "../../redux/userlist/types";
import { getUserListDropdown } from "../../helpers/dropdown";
import { useHistory } from "react-router-dom";
import ROUTES from "../../const/routes";
import { setSelectedUser } from "../../redux/userlist/action";

const User = (): ReactElement => {
  const {
    userApi: { isLoading },
    users,
    selectStream,
  } = useSelector(
    (state: RootState) => ({
      userApi: state.userDetailsList,
      users: state.userDetailsList.users,
      stream: state.streamList.streamMaster,
      selectStream: state.userDetailsList.selectStream as SelectStream,
    }),
    shallowEqual
  );

  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage, setDataPerPage] = useState(10);

  //Get current data
  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = users.slice(indexOfFirstData, indexOfLastData);

  const {
    user: { title },
  } = strings;

  const dispatch = useDispatch();
  const history = useHistory();


  useEffect(() => {
    dispatch(getUsers());
  }, []);

  return (
    <PageWrapper>
      <ContainerWrapper>
        <FlexWrapper justifyContent="space-between">
          <SectionTitle title={title} />
          <Input
            value={search}
            width="25%"
            onChange={(value) => {
              setSearch(value);
            }}
            placeholder="Search"
          />
        </FlexWrapper>
        {/* <UserFilterSection
        roleList={StreamList}
        handleRoleChange={(value: string) => {
          setValues({ ...values, Selectstream: value });
        }}
        handleAdd={(e: SyntheticEvent) => {
          e.preventDefault();
          dispatch(selectStreamInput(values));
        }}
        value={values?.Selectstream || ""}
      /> */}
        {isLoading ? (
          <Loader />
        ) : (
          <div style={{marginTop:'50px'}}>
            <TableWrapper>
              <Table
                size="sm"
                responsive="sm"
                borderless
                className="table table-striped"
              >
                <TableHeader>
                  <TableRow>
                    {tableHeader?.map((header, index) => (
                      <th key={`complete-session-header-${index}`}>{header}</th>
                    ))}
                  </TableRow>
                </TableHeader>
                <tbody>
                  {currentData
                    // eslint-disable-next-line array-callback-return
                    .filter((values) => {
                      if (search === "") {
                        return values;
                      } else if (
                        values?.user_name
                          .toLowerCase()
                          .includes(search.toLowerCase())
                      ) {
                        return values;
                      }
                    })
                    .map((userlists, index) => {
                      const { id, user_name, last_name, mobile, email } =
                        userlists;
                      return (
                        <TableRow key={`user-${index}`}>
                          <td>{index + 1 + indexOfFirstData}</td>
                          <td>{user_name}</td>
                          <td>{last_name}</td>
                          <td>{email}</td>
                          <td>{mobile}</td>
                          <td>
                            <UserlistAction
                              // handleEdit={() => {
                              //   dispatch(setSelectedUser(userlists));
                              //   history.push(ROUTES.EDIT_USER);
                              // }}
                              handleView={() => {
                                dispatch(setSelectedUser(userlists));
                                history.push(ROUTES.USER_DETAILS);
                              }}
                              handleDelete={() =>
                                dispatch(
                                  deleteUser({
                                    id: id,
                                  })
                                )
                              }
                            />
                          </td>
                        </TableRow>
                      );
                    })}
                </tbody>
              </Table>
            </TableWrapper>
            <Pagination
              currentData={indexOfLastData}
              dataLimit={users.length}
              currentPage={currentPage}
              dataPerPage={dataPerPage}
              handleCurrentPage={setCurrentPage}
            />
          </div>
        )}
      </ContainerWrapper>
    </PageWrapper>
  );
};

export default User;
