import { ReactElement, useEffect, useState } from "react";
import { NextButton, PageDetails, PaginationWrapper, PreviousButton } from "./subcomponent";
import { PaginationProps } from "./typings";


const Pagination = ({
  currentData,
  dataLimit,
  currentPage,
  dataPerPage,
  handleCurrentPage,
}: PaginationProps): ReactElement => {
  const [disablePrev, setDisablePrev] = useState(true);
  const [disableNext, setDisableNext] = useState(false); 

  useEffect(() => {
    let totalPages = Math.floor((dataLimit + currentData - 1) / currentData);
    if (currentPage > 1) {
      setDisablePrev(false);
    } else {
      setDisablePrev(true);
    }

    if (totalPages === 1) {
      setDisableNext(true);
    } else {
      setDisableNext(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  return (
    <>
      <PaginationWrapper >
        <PreviousButton
          disabled={disablePrev}
          onClick={() => {
            handleCurrentPage(currentPage - 1);
          }}
        >
          Previous
        </PreviousButton>

        <PageDetails >
          <div id="page">
            Page {currentPage} of{" "}
            {Math.floor((dataLimit + dataPerPage - 1) / dataPerPage)}
          </div>
        </PageDetails>

        <NextButton
          disabled={disableNext}
          onClick={() => {
            handleCurrentPage(currentPage + 1);
          }}
        >
          Next
        </NextButton>
      </PaginationWrapper>
    </>
  );
};

export default Pagination;
