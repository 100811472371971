import { ReactElement } from 'react'
import { H2, Span } from '../../typography'
import Title from './subcomponent'
import { colors } from '../../const/theme'
import { useHistory } from 'react-router-dom'
import BackButton from '../BackButton'

interface SectionProps {
    title: string
    helpText?: string
    hasBorder?: boolean
    hasBackButton?: boolean
}

const SectionTitle = ({
    title,
    helpText,
    hasBorder,
    hasBackButton
}: SectionProps): ReactElement => {
    const history = useHistory()
    return (
        <Title hasBorder={hasBorder}>
            {hasBackButton && (
                <BackButton
                    handleBack={() => {
                        history.goBack()
                    }}
                />
            )}
            <H2 color={colors.title}>{title}</H2>
            {helpText && (
                <div>
                    <Span style={{ color: 'orange', fontWeight: '550' }} isHelpText={true}>{helpText}</Span>
                </div>
            )}
        </Title>
    )
}

export default SectionTitle