import styled from 'styled-components'
import { InputGroup, FormControl } from 'react-bootstrap'
import { colors } from '../../const/theme'
import { EditDropdownWrapperProps } from './typings'
import { H3 } from '../../typography'

export const ListInput = styled(InputGroup)`
  height: 43%;
  #dropdown-id {
    border: 1px solid ${({ theme }) => theme.border};
    &:hover,
    &:focus {
      background: ${({ theme: { dropDown } }) => dropDown.background};
      color: ${({ theme: { dropDown } }) => dropDown.color};
    }
  }
  #input{
    font-family: "Montserrat", sans-serif;
  }
  .dropdown-menu.show {
    max-height: 300px;
    overflow: auto;
    transform: translate(0, 40px) !important;
  }
`

export const Title = styled(H3)`
  margin-bottom: 5px;
`

export const DropdownInput = styled(FormControl)`
  background: ${colors.white} !important;

  &.is-valid + #dropdown-id {
    border-color: ${({ theme: { dropDown } }) => dropDown.success} !important;
  }

  &.is-invalid + #dropdown-id {
    border-color: ${({ theme: { dropDown } }) => dropDown.error};
  }
`

export const EditDropdownWrapper = styled.div<EditDropdownWrapperProps>`
  width: ${({ width }) => width || '100%'};
  @media (max-width: 415px) {
    width: 100%;
  }

  ${({ isDisabled }) =>
    isDisabled &&
    `
pointer-events: none;
opacity: 0.7;
`}
  .form-control.is-valid {
    border-color: ${({ theme: { dropDown } }) => dropDown.success} !important;
  }
`
