import { ReactElement, useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  DropdownWrapper,
  FlexWrapper,
  Input,
  Loader,
  PageWrapper,
  SectionTitle,
  TableHeader,
  TableRow,
  TableWrapper,
  Pagination,
  ContainerWrapper,
  CountTitle,
} from "../../../components";
import { PrimaryButton } from "../../../components/Button";
import { updateSelectedTools } from "../../../redux/AddCategory/action";
import { addTools, EditTools, getTools } from "../../../redux/AddCategory/api";
import { GetTools } from "../../../redux/AddCategory/types";
import { RootState } from "../../../redux/store";
import { tableHeader, resetValues } from "./const";
import ToolsEdit from "./edit";
import { BootStrapModal, ErrorWrapper, ToastMessage, ToolsListAction } from "./subcomponent";

const Tools = (): ReactElement => {
  const {
    category: { isLoading },
    toolsMaster,
    addtoolsDate,
    toolsData,
  } = useSelector((state: RootState) => ({
    toolsMaster: state.categorylist.toolsList,
    addtoolsDate: state.categorylist.addtool,
    category: state.categorylist,
    toolsData: state.categorylist.selectedToolList,
  }));

  const dispatch = useDispatch();
  const [values, setValues] = useState(addtoolsDate);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [resetValuesState, setResetValuesState] = useState(resetValues);
  const [editValues, setEditValues] = useState(toolsData || ({} as GetTools));
  const [showEditModal, setShowEditModal] = useState("");
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [dataPerPage, setDataPerPage] = useState(10);
  const [showModal, setShowModal] = useState(false)
  const LastData = currentPage * dataPerPage;
  const firstData = LastData - dataPerPage;
  const currentData = toolsMaster.slice(firstData, LastData);

  useEffect(() => {
    dispatch(getTools());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setEditValues(toolsData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toolsData]);

  const istoolNameSame = currentData.some(
    (item) => item.toolName === values?.toolName
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (istoolNameSame) {
      return setShowModal(true)
    } else return
  })

  const clearValues = () => {
    setResetValuesState({
      toolName: true,
    });
    setValues(addtoolsDate);
  };

  const canSave = !!values.toolName;

  return (
    <PageWrapper>
      <ContainerWrapper>
        <FlexWrapper noPadding >
          <SectionTitle title="Add Tools" />
        </FlexWrapper>

        {showModal && (
          <>
            <ToastMessage
              show={true}
              bg="danger"
              autohide
              delay={1000}
              onClose={() => setShowModal(false)}
            >
              <ErrorWrapper>Tool Already Exists!</ErrorWrapper>
            </ToastMessage>
          </>
        )}

        <FlexWrapper>
          <CountTitle title="Total Tool" count={toolsMaster.length} />
          <DropdownWrapper>
            <Input
              value={values?.toolName || ""}
              onChange={(value: string) => {
                setValues({ ...values, toolName: value });
              }}
              placeholder="Add Tools"
            />
          </DropdownWrapper>
          <PrimaryButton
            style={{ marginTop: "1px" }}
            onClick={() => {
              dispatch(addTools(values));
              clearValues();
            }}
            disabled={!canSave}
          >
            Submit
          </PrimaryButton>
        </FlexWrapper>
        <FlexWrapper justifyContent="space-between">
          <SectionTitle title="Tools" />
          <Input
            value={search}
            width="25%"
            placeholder="Search"
            onChange={(value: string) => {
              setSearch(value);
            }}
          />
        </FlexWrapper>
        {isLoading ? (
          <Loader />
        ) : (
          <div style={{ marginTop: "50px" }}>
            <TableWrapper>
              <Table
                size="sm"
                responsive="sm"
                borderless
                className="table table-striped"
              >
                <TableHeader>
                  <TableRow>
                    {tableHeader.map((header, index) => (
                      <th key={`tools-${index}`}>{header}</th>
                    ))}
                  </TableRow>
                </TableHeader>
                <tbody>
                  {currentData
                    // eslint-disable-next-line array-callback-return
                    .filter((toolsValue) => {
                      if (search === "") {
                        return toolsValue;
                      } else if (
                        toolsValue?.toolName
                          .toLowerCase()
                          .includes(search.toLowerCase())
                      ) {
                        return toolsValue;
                      }
                    })
                    .map((toolsInfo, index) => {
                      const { toolName, tool_id } = toolsInfo;
                      return (
                        <TableRow key={`tool-${index}`}>
                          <td>{index + 1 + firstData}</td>
                          <td>{toolName}</td>
                          <td>
                            <ToolsListAction
                              handleEdit={() => {
                                setShowEditModal(tool_id);
                                dispatch(updateSelectedTools(toolsInfo));
                              }}
                            />
                          </td>
                        </TableRow>
                      );
                    })}
                </tbody>
              </Table>
              <Pagination
                currentData={LastData}
                dataLimit={toolsMaster.length}
                currentPage={currentPage}
                dataPerPage={dataPerPage}
                handleCurrentPage={setCurrentPage}
              />
            </TableWrapper>
          </div>
        )}
        {showEditModal && (
          <BootStrapModal
            handleCancel={() => setShowEditModal("")}
            handleSubmit={() => {
              dispatch(EditTools(editValues));
              setShowEditModal("");
            }}
            isLargeModal={editValues?.toolName.length > 20 ? true : false}
            title={"Edit Tool"}
          >
            <ToolsEdit editValues={editValues} setEditValues={setEditValues} />
          </BootStrapModal>
        )}
      </ContainerWrapper>
    </PageWrapper>
  );
};

export default Tools;
