const fonts = {
  tiny: 16,
  small: 17,
  medium: 19,
  large: 22,
  xLarge: 28,
};

export const weight = {
  normal: 400,
  bold: 600,
  xBold: 800
}

export default fonts
