import styled from "styled-components"
import { Form, Button } from "react-bootstrap"
import { H1, H3 } from "../../typography"
import fonts from "../../const/fonts"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export const LoginWrapper = styled.div`
display: flex;
width: 100%;
background: #e6e6e6;
height: 100vh;
border-radius: 4px;
`
export const Title = styled(H1)`
text-align: center;
margin-top: 24px;
font-family: "Montserrat", sans-serif;
color:#0000FF;
`
export const Container = styled.div`
width: 100%;
max-width: 80%;
  height: 300px;
  margin: auto;
  @media (min-width: 749px) {
    width: 35%;
  }
`
export const SubTitle = styled(H3)`
  text-align: center;
`
export const FormWrapper = styled.div`
background: white;
padding: 0.1rem 0.1rem;
border-radius: 7px;
font-family: "Montserrat", sans-serif;
box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.65);
`
export const SubmitButton = styled(Button)`
display: block;
margin: auto;
border: none;
font-size: 14px;
background-color:#F47F34;
margin-top:3%;
&:hover{
  background-color:#F47F34;
  box-shadow: 1px 5px 18px orange;
}
`
export const FormContainer = styled(Form)`
display: flex;
max-width: 75%;
margin: 20px auto;
flex-direction: column;
justify-content: center;
`
export const Icon = styled(FontAwesomeIcon)`
font-size: ${fonts.medium}px;
color: ${({ theme }) => theme?.icon?.normal};
margin: auto 8px;
`
export const Label = styled.div`
margin-left:35%;
color:black;
margin-top:3%;
`