const ROUTES = {
  LOGIN: "/",
  DASHBOARD: "/dashboard",
  USER: "/user",
  STREAM: "/stream",
  ADD_CATEGORY: "/addCategory",
  ASSIGN_CATEGORY: "/assignCategory",
  QUESTIONNAIRE_MANAGEMENT: "/questionnaireManagement",
  CREATE_QUESTION: "/creatTest",
  STREAM_TABLE: "/streamTable",
  VIEW_QUESTION: "/questionTables",
  USER_DETAILS: "/userDetails",
  STREAM_DETAILS: "/streamDetails",
  ASSIGNCATEGORY_DETAILS: "/assignCategoryDetails",
  ADDCATEGORY_DETAILS: "/addCategoryDetails",
  TOOLS: "/tools",
  EDIT_USER: "/editUser",
  ADDCATEGORY_EDIT: "/addCategoryEdit",
  TOOLS_EDIT: "/toolsEdit",
  PRODUCT_HEAD: "/createProductHead",
  RESULT: "/result",
};

export default ROUTES;
