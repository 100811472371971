import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { addStreamMasterInput, deleteStream, handleStreamMaster } from "./api";
import { InitialState, StreamList } from "./types";

const initialState: InitialState = {
  isLoading: false,
  streamMaster: [],
  streamList: null,
  selectStreamMaster: {
    stream: "",
  }, 
  selectStreamDetails: null
};
export const streamSlice = createSlice({
  name: "streamMaster",
  initialState,
  reducers: {
    updateHasError: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setStreamSelected: (state, action: PayloadAction<StreamList | null>) => {
      state.selectStreamDetails = action?.payload
    }
  },
  extraReducers: {
    [handleStreamMaster.pending.toString()]: (state) => {
      state.isLoading = true;
    },
    [handleStreamMaster.fulfilled.toString()]: (
      state,
      action: PayloadAction<Array<StreamList>>
    ) => {
      state.isLoading = false;
      state.streamMaster = action?.payload;
    },
    [handleStreamMaster.rejected.toString()]: (state) => {
      state.isLoading = false;
    },
    [addStreamMasterInput.pending.toString()]: (state) => {
      state.isLoading = true;
    },
    [addStreamMasterInput.fulfilled.toString()]: (state) => {
      state.isLoading = false;
    },
    [addStreamMasterInput.rejected.toString()]: (state) => {
      state.isLoading = false;
    }, 
    [deleteStream.pending.toString()]: (state) => {
      state.isLoading = true
    },
    [deleteStream.rejected.toString()]: (state) => {
      state.isLoading = false
    },
    [deleteStream.fulfilled.toString()]: (state) => {
      state.isLoading = false
    }
  },
});

export default streamSlice.reducer;
