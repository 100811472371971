import styled from "styled-components";
import { weight } from "../../const/fonts";
import { InputWrapperProps } from "./typings";

export const InputWrapper = styled.div<InputWrapperProps>`
  width: ${({ width }) => width || "100%"};
  display: flex;
  flex-direction: column; 
  box-shadow: ${({ boxShadow }) => (boxShadow ? "0" : "1px 1px 5px lightgray")};
  border: none;
  opacity: 1; 
  margin-bottom: ${({ hasPadding }) => (hasPadding ? "12px" : "0")};
  #edu-input{
    font-family: "Montserrat", sans-serif; 
    font-size: 14px;
    font-weight: 500;
    ::placeholder{ 
    font-family: "Montserrat", sans-serif; 
    color: #141A45;
    font-size: 14px;
    font-weight: 440;
  }
  }
  @media (max-width: 415px) {
    width: 100%;
  }

  ${({ isDisabled }) => isDisabled && `pointer-events: none;opacity: 0.7;`}
  .form-control:focus {
    box-shadow: none; 
  }
`;

export const InputLabel = styled.label`
  font-weight: ${weight.bold};
`;
