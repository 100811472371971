import { userSlice } from "./reducer"
import { handleAuthenticate } from "./api";
const { updateHasError, updateIsLoggedIn, updatePassword, updateUserName } =
  userSlice.actions;

export {
  updateHasError,
  updateIsLoggedIn,
  updatePassword,
  updateUserName,
  handleAuthenticate
};