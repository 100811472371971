import { streamSlice } from "./reducer";
import { handleStreamMaster, addStreamMasterInput, deleteStream } from "./api";

const { updateHasError, setStreamSelected } = streamSlice.actions;

export {
    updateHasError,
    handleStreamMaster,
    addStreamMasterInput,
    setStreamSelected,
    deleteStream
};
