import { ReactElement } from "react"
import { shallowEqual, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { BackButton, CardWrapper, Column, FlexWrapper, PageWrapper } from "../../../../components"
import { RootState } from "../../../../redux/store"

const AddCategoryDetails = (): ReactElement => {
    const { selectedcategoryDetails } = useSelector(
        (state: RootState) => ({
            selectedcategoryDetails: state.categorylist.selectedcategoryDetails
        }),
        shallowEqual
    );

    const history = useHistory()

    return (
        <PageWrapper id="container">
            <div>
                <BackButton
                    handleBack={() => {
                        history.goBack()
                    }}
                />
            </div>
            <FlexWrapper justifyContent="space-around">
                <CardWrapper>
                    <Column keyName="Name" value={selectedcategoryDetails?.name} />
                    <Column keyName="Description" value={selectedcategoryDetails?.description} />
                    {selectedcategoryDetails.tools.map((toolsInfo) => (
                        <Column keyName="Tools Name" value={toolsInfo?.name} />
                    ))}
                </CardWrapper>


            </FlexWrapper>
        </PageWrapper>
    )
}

export default AddCategoryDetails
