import styled from "styled-components";

export const ContainerWrapper = styled.div` 
width: 100%;
padding: 12px ;
border-radius: 5px;
display: block; 
justify-content: center;
box-shadow: 0 0 10px lightgray;
background-color: white;
`