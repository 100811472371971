import styled from 'styled-components'
import { colors } from '../../const/theme'
import Button from 'react-bootstrap/esm/Button'

const Buttons = styled(Button)` 
  background-color:gray;
  color: ${colors.white};
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  border: none;
  &:focus,
  &:hover,
  &:active{
    background: #f27d30 !important;
    color: ${colors.white};
    border: none;
  }
  margin: auto 4px;
`

export default Buttons
