import { ReactElement } from "react";
import {
  HeaderWrapper,
  ProfileContainer,
  ProfileImage,
  ProfileWrapper,
  UserName
} from "./subcomponents";
import DesktopMenu from "./DesktopMenu";
import MobileMenu from "./MobileMenu";
import useBreakpoint from "use-breakpoint";
import BREAKPOINTS from "../../const/breakpoint";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import user from '../../asserts/user.png'

const Header = (): ReactElement => {
  const {
    name
  } = useSelector(
    (state: RootState) => ({
      name: state.user.userInfo.name
    })
  )
  const { breakpoint } = useBreakpoint(BREAKPOINTS);

  return (
    <>
      <HeaderWrapper>
        {breakpoint === "mobile" ? <MobileMenu /> : <DesktopMenu />}
        <ProfileWrapper>
          <ProfileContainer>
            <ProfileImage src={user} />
            <UserName>{name}</UserName>
          </ProfileContainer>
        </ProfileWrapper>
      </HeaderWrapper>
    </>
  );
};

export default Header;
