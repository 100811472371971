import { ReactElement } from "react"
import {  useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { BackButton, CardWrapper, Column, FlexWrapper, PageWrapper } from "../../../components"
import strings from "../../../locale/en"
import { RootState } from "../../../redux/store"
import { StreamList } from "../../../redux/streamMaster/types" 

const StreamDetails = (): ReactElement => {
    const {
        streamDetails
    } = useSelector(
        (state: RootState) => ({
            streamDetails: state.streamList.selectStreamDetails as StreamList
        })
    )
    const {
        details: {
            streamDetails: {
                name,
                createdat,
                updatedat,
                status,
                divisionid,
            }
        }
    } = strings
    const history = useHistory()

    return (
        <PageWrapper id="container">
            <div>
                <BackButton
                    handleBack={() => {
                        history.goBack()
                    }}
                />
            </div>
            <FlexWrapper justifyContent="space-around">
                <CardWrapper>
                    <Column keyName={name} value={streamDetails?.name} />
                    <Column keyName={status} value={streamDetails?.status} />
                    <Column keyName={createdat} value={streamDetails?.created_at} />
                </CardWrapper>
                <CardWrapper>
                    <Column keyName={updatedat} value={streamDetails?.updated_at} /> 
                    <Column keyName={divisionid} value={streamDetails?.division_id} />
                </CardWrapper>
            </FlexWrapper>
        </PageWrapper>
    )
}

export default StreamDetails